import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Card, Descriptions, Input, message, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { IPlugins, ITenantConfig } from "../../pages/Tenant/types";
import { ICompletedTenant, IInternalPolicy } from "../../pages/AddTenant/types";
import { EModes, INotification } from "../../pages/Tenants/types";
import { updateTenant } from "../../http/userApi";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";

import "./style.scss";

const General = ({
  name,
  descr,
  plugins,
  config,
  internalPolicies,
}: {
  name: string;
  descr: string;
  plugins: IPlugins;
  config: ITenantConfig;
  internalPolicies: {
    Statement: IInternalPolicy[];
  }[];
}) => {
  const msalInstance = useMsal();
  const [loading, setLoading] = useState(false);
  const [configData, setConfigData] = useState<{
    awsAccountId: number;
    targetRole: string;
  }>({
    awsAccountId: 0,
    targetRole: "",
  });
  const [inputValue, setInputValue] = useState({
    descr: "",
  });
  const [generalEdit, setGeneralEdit] = useState(false);
  const [accountEdit, setAccountEdit] = useState(false);

  const [notification, setNotification] = useState<INotification | null>(null);

  const switchToGeneralEdit = () => setGeneralEdit(!generalEdit);

  const switchToAccountEdit = () => setAccountEdit(!accountEdit);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification]);

  useEffect(() => {
    if (config) {
      setConfigData({
        ...configData,
        awsAccountId: config.AWS_ACCOUNT_ID,
        targetRole: config.TARGET_ROLE_ARN,
      });
      setInputValue({ descr: descr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue({
      descr: e.target.value,
    });
  };

  const handleAccountSettings = (e: ChangeEvent<HTMLInputElement>) => {
    setConfigData({ ...configData, [e.target.name]: e.target.value });
  };

  const handleSaveEdit = useCallback(async () => {
    try {
      setLoading(true);

      const newData: ICompletedTenant = {
        EntityName: name,
        Description: inputValue.descr,
        Plugins: plugins,
        InternalPolicies: [...internalPolicies],
        Addons: {},
        Config: {
          ...config,
          AWS_ACCOUNT_ID: configData.awsAccountId,
          TARGET_ROLE_ARN: configData.targetRole,
        },
      };

      const result: {
        data: string | null;
        error: null | string;
      } = await updateTenant(newData, msalInstance);

      if (result?.data) {
        message.success({
          content: result.data,
          duration: 3,
        });
      }

      if (result.error) {
        setNotification({
          mode: EModes.Error,
          title: result.error,
        });
      }
    } finally {
      setLoading(false);
      setGeneralEdit(false);
      setAccountEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, plugins]);

  return (
    <>
      <Card
        title="General"
        className="general-card"
        extra={
          generalEdit ? (
            <>
              <Button onClick={switchToGeneralEdit} className="cancel-btn">
                Cancel
              </Button>
              <Button onClick={handleSaveEdit} type="primary">
                Save
              </Button>
            </>
          ) : (
            <Button onClick={switchToGeneralEdit} icon={<EditOutlined />}>
              Edit
            </Button>
          )
        }
      >
        {loading ? (
          <Spinner />
        ) : (
          <Descriptions>
            <Descriptions.Item
              label="Description"
              labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
            >
              {!generalEdit ? (
                descr ? (
                  descr
                ) : (
                  "-"
                )
              ) : (
                <Input
                  value={inputValue.descr}
                  onChange={handleChangeDescription}
                />
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
      <Card
        title="Account settings"
        className="account-card"
        extra={
          accountEdit ? (
            <>
              <Button onClick={switchToAccountEdit} className="cancel-btn">
                Cancel
              </Button>
              <Button onClick={handleSaveEdit} type="primary">
                Save
              </Button>
            </>
          ) : (
            <Button onClick={switchToAccountEdit} icon={<EditOutlined />}>
              Edit
            </Button>
          )
        }
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Descriptions>
              <Descriptions.Item
                label="AWS account ID"
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!accountEdit ? (
                  configData.awsAccountId
                ) : (
                  <Input
                    name="awsAccountId"
                    type="number"
                    value={configData.awsAccountId}
                    onChange={handleAccountSettings}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item
                label="Target IAM role ARN"
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!accountEdit ? (
                  configData.targetRole
                ) : (
                  <Input
                    name="targetRole"
                    value={configData.targetRole}
                    onChange={handleAccountSettings}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Card>
    </>
  );
};

export default memo(General);
