import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./config/auth";
import App from "./App";

import "antd/dist/antd.min.css";
import "./scss/style.scss";
import { Provider } from "react-redux";
import { store } from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

export const msalInstance: IPublicClientApplication =
  new PublicClientApplication(msalConfig);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </Provider>,
);
