import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  arePoliciesLoaded,
  getPolicyById,
} from "../../store/Policies/selectors";
import { ApplicationState } from "../../store/types";
import { Space, Spin } from "antd";
import { ITenantProps } from "../Tenant/types";

import AddPolicy from "../AddPolicy";

type IProps = {
  policyPage: { title: string; policyId?: string };
  tenant: ITenantProps;
  id: string;
  setPolicyPage: (value: { title: string; policyId?: string }) => void;
};

const EditPolicy = ({ policyPage, tenant, id, setPolicyPage }: IProps) => {
  const navigate = useNavigate();
  const isLoaded = useSelector(arePoliciesLoaded);
  const policy = useSelector((state: ApplicationState) =>
    getPolicyById(state, id),
  );

  useEffect(() => {
    if (!policy && isLoaded) {
      navigate("/404");
    }
  }, [policy, navigate, isLoaded]);

  return isLoaded && policy ? (
    <AddPolicy
      policyPage={policyPage}
      tenant={tenant}
      policy={policy}
      setPolicyPage={setPolicyPage}
    />
  ) : (
    <div className="spinner-wrapper">
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default memo(EditPolicy);
