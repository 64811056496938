import { generateStatementsFromEntity } from "../../helpers";
import { IPolicy } from "../../pages/Policies/types";
import { ApplicationState } from "../types";
import { IPolicyEntity } from "./types";

export const getAllPolicies = (state: ApplicationState): Array<IPolicy> => {
  return Object.values(state.policiesSlice.data).map(item => {
    return {
      ...item,
      Statement: generateStatementsFromEntity(item.Statement),
    };
  });
};

export const arePoliciesLoaded = (state: ApplicationState): boolean => {
  return state.policiesSlice.isLoaded;
};

export const getNotification = (state: ApplicationState) => {
  return state.policiesSlice.notification;
};

export const getPolicyById = (
  state: ApplicationState,
  id?: string,
): IPolicy | undefined => {
  if (id && state.policiesSlice.data[id]) {
    const item = state.policiesSlice.data[id];
    return { ...item, Statement: generateStatementsFromEntity(item.Statement) };
  }
};

export const getRawPolicyById = (
  state: ApplicationState,
  id?: string,
): IPolicyEntity | undefined => {
  if (id && state.policiesSlice.data[id]) {
    return state.policiesSlice.data[id];
  }
};
