import { loadHints } from "./actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Hints } from "./types";

const hintsSlice = createSlice({
  name: "Hints",
  initialState: {
    isLoaded: false,
    data: {
      action: {
        values: [
          { name: "authz:GetKeys", description: "" },
          { name: "authz:OnOff", description: "" },
          { name: "pserver:EvaluatePolicy", description: "" },
          { name: "pserver:ViewEvaluateContext", description: "" },
          { name: "datapond:PutBucket", description: "" },
          { name: "datapond:DeleteBucket", description: "" },
          { name: "datapond:CreateRWMachineUser", description: "" },
        ],
      },
      resource: {
        values: [
          { name: "*", description: "" },
          { name: "arn:aws:iam::*:role/*", description: "" },
          { name: "arn:aws:iam::*:user/*", description: "" },
          { name: "arn:aws:s3*", description: "" },
          { name: "arn:aws:athena*", description: "" },
          { name: "arn:aws:rds*", description: "" },
          { name: "datapond:bucket:*", description: "" },
        ],
      },
      condition: {
        elements: {},
      },
    },
  },
  reducers: {},
  extraReducers: {
    [loadHints.fulfilled.toString()]: (
      state: {
        isLoaded: boolean;
        data: Hints;
      },
      action: PayloadAction<{
        hints: Hints;
      }>,
    ): void => {
      state.data = action.payload.hints;
      state.isLoaded = true;
    },
  },
});

export default hintsSlice.reducer;
