import { memo, useCallback, useState } from "react";
import { Popover } from "antd";

import "./style.scss";

const ListPopup = ({ list }: { list: Array<string> }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = useCallback(
    (newVisible: boolean) => {
      setVisible(newVisible);
    },
    [setVisible],
  );

  return (
    <div>
      <ul className="popup-list">
        {list.map((subItem, index) =>
          index < 2 ? <li key={subItem}>{subItem}</li> : null,
        )}
      </ul>
      {list.length > 2 ? (
        <Popover
          content={
            <ul className="popup-list">
              {list.map((item, index) =>
                index >= 2 ? <li key={item}>{item}</li> : null,
              )}
            </ul>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <div className="tenantList-button">
            <span></span>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export default memo(ListPopup);
