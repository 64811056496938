import { memo } from "react";
import { Space, Spin } from "antd";

import "./style.scss";

const Spinner = ({ className }: { className?: string }) => {
  return (
    <>
      <div className={`spinner ${className ? className : ""}`}>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    </>
  );
};

export default memo(Spinner);
