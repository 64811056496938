import { memo, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { ParseError, SchemaValidationError } from "jsoneditor";

import DiscardModal from "../../components/Policies/DiscardModal";
import PolicyForm from "../../components/Policies/PolicyForm";
import { AppDispatch } from "../../store";
import { ConditionEntity, NewJsonPolicy, StatementEntity } from "../../const";
import { createPolicy, updatePolicy } from "../../store/Policies/actions";
import {
  getNotification,
  getRawPolicyById,
} from "../../store/Policies/selectors";

import {
  generateConditions,
  generateStatementsFromEntity,
  generateStatementsToEntity,
  getCondition,
} from "../../helpers";

import { IPolicyEntity, IStatementEntity } from "../../store/Policies/types";
import { IPolicy, IStatement } from "./types";
import { ApplicationState } from "../../store/types";
import { deleteNotification } from "../../store/Policies/slice";
import { ITenantProps } from "../Tenant/types";

import Editor from "../../components/Policies/Editor";

import "./style.scss";

type IProps = {
  policyPage?: { title: string; policyId?: string };
  tenant?: ITenantProps;
  policy?: IPolicy;
  setPolicyPage: (value: { title: string; policyId?: string }) => void;
};

const AddPolicy = ({ policyPage, tenant, policy, setPolicyPage }: IProps) => {
  const msalInstance = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const notification = useSelector(getNotification);

  const [policyId, setPolicyId] = useState(policy?.Id || "");
  const [validationError, setValidationError] = useState<string>("");
  const [hasValidationErrors, setErrors] = useState<
    readonly (SchemaValidationError | ParseError)[]
  >([]);
  const [hasStatementError, setStatementError] = useState<boolean>(false);
  const [statements, setStatements] = useState<IStatement[]>(
    policy?.Statement || [StatementEntity],
  );

  const policyById = useSelector((state: ApplicationState) =>
    getRawPolicyById(state, policyId),
  );

  const [jsonPolicy, setJsonPolicy] = useState(policyById || NewJsonPolicy);

  const [segment, setSegment] = useState<SegmentedValue>("Advanced mode");

  const [isSaveClicked, setSaveClicked] = useState(false);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        content: notification.subTitle,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: `${notification.connection ? "" : "none"}`,
          },
        },
        cancelText: "Cancel",
        onCancel: () => navigate("/"),
        closable: true,
        maskClosable: false,
        afterClose() {
          dispatch(deleteNotification());
          modal.destroy();
        },
      });
    } else if (notification && notification.mode === "success") {
      setPolicyPage({ title: "list" });
    }
  }, [notification, dispatch, navigate, setPolicyPage]);

  useEffect(() => {
    !hasValidationErrors.length && validationError && setValidationError("");
  }, [hasValidationErrors, validationError]);

  const handleChangeStatement = useCallback(
    (id: string, field: string, value: string | Array<string>) => {
      setStatements(
        statements.map(item =>
          item.Id === id ? { ...item, [field]: value } : item,
        ),
      );
    },
    [statements],
  );

  const handleChangeCondition = useCallback(
    (
      statementId: string,
      conditionId: string,
      field: string,
      value?: string | Array<string>,
    ) =>
      setStatements(
        statements.map(item =>
          item.Id === statementId
            ? {
                ...item,
                Condition: generateConditions(
                  item.Condition,
                  conditionId,
                  field,
                  value,
                ),
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleChangeCheckBox = useCallback(
    (statementId: string, conditionId: string, field: string, value: boolean) =>
      setStatements(
        statements.map(item =>
          item.Id === statementId
            ? {
                ...item,
                Condition: item.Condition?.map(subItem =>
                  subItem.Id === conditionId
                    ? {
                        ...subItem,
                        [field]: value,
                      }
                    : subItem,
                ),
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleAddCondition = useCallback(
    (id: string) =>
      setStatements(
        statements.map((item: IStatement) =>
          item.Id === id
            ? {
                ...item,
                Condition: [
                  ...item.Condition,
                  { ...ConditionEntity, Id: uuidv4() },
                ],
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleDuplicateCondition = useCallback(
    (statementId: string, conditionId: string) => {
      const condition = getCondition(statementId, conditionId, statements);

      const newStatement = statements.map((item: IStatement) =>
        item.Id === statementId
          ? {
              ...item,
              Condition: [...item.Condition, { ...condition, Id: uuidv4() }],
            }
          : item,
      );

      return setStatements(newStatement);
    },
    [statements],
  );

  const handleDeleteCondition = useCallback(
    (statementId: string, conditionId: string) =>
      setStatements(
        statements.map(item =>
          item.Id === statementId
            ? {
                ...item,
                Condition: item.Condition.filter(
                  condition => condition.Id !== conditionId,
                ),
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleDeleteStatement = useCallback(
    (statementId: string) =>
      setStatements(statements.filter(item => item.Id !== statementId)),
    [statements],
  );

  const handleAddStatment = useCallback(
    () => setStatements([...statements, { ...StatementEntity, Id: uuidv4() }]),
    [statements],
  );

  const saveHandler = useCallback(() => {
    if (hasValidationErrors.length) {
      setValidationError(
        "The policy cannot be saved until JSON contains errors.",
      );
      return;
    }

    if (!statements.length) {
      setStatementError(true);
      return;
    }
    setSaveClicked(true);
    const Statement: IStatementEntity[] =
      segment === "Advanced mode"
        ? generateStatementsToEntity(statements)
        : jsonPolicy.Statement;

    if (tenant && policyPage?.title === "edit") {
      dispatch(
        updatePolicy({ PolicyId: policyId, Statement, tenant, msalInstance }),
      );
    } else if (tenant) {
      dispatch(
        createPolicy({ PolicyId: policyId, Statement, tenant, msalInstance }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    policyPage?.title,
    statements,
    policyId,
    dispatch,
    hasValidationErrors,
    segment,
    jsonPolicy.Statement,
    tenant,
    msalInstance,
  ]);

  const changeSegment = useCallback(
    (value: SegmentedValue) => {
      const cantSwitchErrors = hasValidationErrors.find(error => {
        const errorWithType = error as unknown as { keyword: string };
        return (
          errorWithType.keyword !== "required" &&
          errorWithType.keyword !== "minItems" &&
          errorWithType.keyword !== "minProperties"
        );
      });

      if (cantSwitchErrors) {
        return setValidationError(
          "JSON view cannot be switched while JSON contains errors.",
        );
      }
      if (value === "Advanced mode") {
        setStatements(generateStatementsFromEntity(jsonPolicy.Statement));
      } else {
        setJsonPolicy({
          Id: policyId,
          Statement: generateStatementsToEntity(statements),
        });
      }
      return setSegment(value);
    },
    [hasValidationErrors, policyId, statements, jsonPolicy.Statement],
  );

  const changeJSON = useCallback(
    (item: IPolicyEntity) => {
      setJsonPolicy(item);
      !policy && setPolicyId(item.Id);
    },
    [policy],
  );

  return (
    <div className="add-policy">
      <DiscardModal
        oldStatements={policy?.Statement || [StatementEntity]}
        changedStatements={statements}
        oldPolicyId={policy?.Id || ""}
        changedPolicyId={policyId}
        isSaveButtonClicked={isSaveClicked}
        json={jsonPolicy}
        oldJson={policyById || NewJsonPolicy}
      />
      <>
        <Segmented
          options={["Advanced mode", "JSON view"]}
          value={segment}
          onChange={changeSegment}
          size="large"
          className="tabs"
        />
        {segment === "JSON view" ? (
          <Editor
            onChangeJSON={changeJSON}
            json={jsonPolicy}
            oldJson={policyById || NewJsonPolicy}
            savePolicy={saveHandler}
            setErrors={setErrors}
            error={validationError}
            isAddPage={!policy}
          />
        ) : (
          <PolicyForm
            changeStatement={handleChangeStatement}
            changeCondition={handleChangeCondition}
            addCondition={handleAddCondition}
            deleteCondition={handleDeleteCondition}
            changeCheckBox={handleChangeCheckBox}
            deleteStatement={handleDeleteStatement}
            policy={policy}
            policyId={policyId}
            isEditPage={!!policy}
            changePolicyId={setPolicyId}
            savePolicy={saveHandler}
            addStatement={handleAddStatment}
            statements={statements}
            hasError={hasStatementError}
            duplicateCondition={handleDuplicateCondition}
          />
        )}
      </>
    </div>
  );
};

export default memo(AddPolicy);
