import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  RefObject,
} from "react";

import { Alert, Button, Card, Form, message } from "antd";
import JSONEditor, {
  JSONEditorOptions,
  ParseError,
  SchemaValidationError,
  ValidationError,
} from "jsoneditor";
import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";

import { IPolicy } from "../../../pages/AddPolicy/types";
import { IPolicyEntity } from "../../../store/Policies/types";

import "jsoneditor/dist/jsoneditor.css";
import "./style.scss";

type IProps = {
  onChangeJSON?: (item: IPolicyEntity) => void;
  isPreview?: boolean;
  json?: IPolicyEntity;
  oldJson?: IPolicyEntity;
  savePolicy?: () => void;
  setErrors?: (errors: readonly (SchemaValidationError | ParseError)[]) => void;
  error?: string;
  isAddPage?: boolean;
};

const Editor = ({
  onChangeJSON,
  isPreview,
  json,
  savePolicy,
  setErrors,
  error,
  oldJson,
  isAddPage,
}: IProps) => {
  const container: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [jsonEditor, setJSONEditor] = useState<JSONEditor>();

  const changeText = useCallback(
    (js: string) => {
      try {
        if (js && JSON.parse(js)) {
          onChangeJSON?.(JSON.parse(js) as IPolicyEntity);
        }
      } catch {
        return;
      }
    },
    [onChangeJSON],
  );

  const options: JSONEditorOptions = useMemo(
    () => ({
      mode: isPreview ? "preview" : "code",
      onChangeText: changeText,
      statusBar: false,
      history: false,
      mainMenuBar: false,
      onValidate: (
        js: IPolicy,
      ): ValidationError[] | Promise<ValidationError[]> => {
        const errors = [];

        if (!isAddPage && json?.Id && js.Id !== json?.Id) {
          errors.push({
            path: ["Id"],
            message: "Id does not need to be changed",
          });
        }

        if (!js?.Id) {
          errors.push({
            path: ["Id"],
            message: "Field is required",
          });
        }

        return errors;
      },
      onValidationError: (
        errors: readonly (SchemaValidationError | ParseError)[],
      ) => {
        setErrors?.(errors);
      },
    }),
    [isPreview, isAddPage, changeText, json?.Id, setErrors],
  );

  useEffect(() => {
    container.current &&
      setJSONEditor(new JSONEditor(container.current, options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  useEffect(() => {
    jsonEditor && json && jsonEditor.set(json);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonEditor]);

  useEffect(() => {
    const editor = document.querySelector(".jsoneditor") as HTMLElement;
    if (editor) {
      editor.style.height = `${
        JSON.stringify(json, null, 4).split("\n").length *
          (!isPreview ? 17 : 22) +
        14
      }px`;
    }
  }, [isPreview, json]);

  const handleCopyJSON = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(json));
    message.success({
      content: "JSON is copied",
      duration: 1,
    });
  }, [json]);

  const handleResetJSON = useCallback(() => {
    if (oldJson) {
      onChangeJSON?.(oldJson);
      jsonEditor?.set(oldJson);
    }
  }, [oldJson, onChangeJSON, jsonEditor]);

  return (
    <Form
      name="basic"
      onFinish={savePolicy}
      className="editor-form"
      id="policyForm"
    >
      {!isPreview ? (
        <>
          {error && (
            <Alert message={error} type="error" showIcon className="alert" />
          )}
          <Card
            className="editor-card"
            title="Statements and conditions"
            bodyStyle={{
              borderTop: "1px solid #F0F0F0",
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              background: "#F5F5F5",
            }}
            extra={[
              <Button
                size="middle"
                icon={<ReloadOutlined />}
                onClick={handleResetJSON}
                className="reload-button"
              >
                Reset
              </Button>,
              <Button
                size="middle"
                icon={<CopyOutlined />}
                onClick={handleCopyJSON}
              >
                Copy JSON
              </Button>,
            ]}
          >
            <div className="editor" ref={container} />
          </Card>
        </>
      ) : (
        <div className="editor" ref={container} />
      )}
    </Form>
  );
};

export default React.memo(Editor);
