import { memo, useEffect, useState } from "react";
import {
  Form,
  message,
  Modal,
  PageHeader,
  notification as AntNotification,
} from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";

import { addTenantBreadcrumbs, initialTenant } from "../../const";
import { EModes, INotification, ITenant } from "../Tenants/types";
import { createTenant } from "../../http/userApi";
import { useMsal } from "@azure/msal-react";
import { ICompletedTenant } from "./types";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";
import GeneralForm from "../../components/GeneralForm";
import TokenForm from "../../components/TokenForm";
import AccountForm from "../../components/AccountForm";

import "./style.scss";

const AddTenant = () => {
  const msalInstance = useMsal();
  const [data, setData] = useState<ITenant>(initialTenant);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const accountName: string | undefined =
    msalInstance.accounts[0]?.username || "";

  const [notification, setNotification] = useState<INotification | null>(null);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification, navigate]);

  const itemRender = (route: Route) => {
    if (route.path === "/addTenant") {
      return <div>{route.breadcrumbName}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const handleSave = async () => {
    if (new RegExp(/[a-z]{4,20}/g).test(data.tenantId) === false) {
      AntNotification.error({
        message: "Error",
        description:
          "Tennant ID format is incorrect. Letters must be in lower case. Minimum number of characters is 4",
      });

      return;
    } else if (new RegExp(/\s+/g).test(data.tenantId)) {
      AntNotification.error({
        message: "Error",
        description:
          "Tennant ID format is incorrect. Should remove spaces from the field",
      });

      return;
    }

    try {
      setLoading(true);

      const newData: ICompletedTenant = {
        EntityName: data.tenantId,
        Description: "",
        Plugins: {
          SecretsIamSession: {
            enabled: true,
            pattern: "arn:aws:iam::*:role/*",
            SKIP_SESSION_TAGS: false,
            MAX_AD_GROUPS: 3,
          },
          SecretsIamUser: {
            enabled: true,
            pattern: "arn:aws:iam::*:user/*",
            IAM_GROUP_TARGET_PREFIX: "_dpadu-prod-",
            SKIP_USER_TAGS: false,
            IAM_USER_SESSION_LIFETIME: "1",
            SESSION_MODE: true,
            MAX_AD_GROUPS: 3,
          },
          AuthAllowAll: {
            enabled: true,
          },
          AuthPolicyEngine: {
            enabled: true,
            policies: [
              {
                Id: "DefaultPolicy",
                Statement: [
                  {
                    Id: "1",
                    Effect: "Allow",
                    Action: ["*"],
                    Resource: ["arn:aws:iam::*:role/*"],
                    Condition: {},
                  },
                ],
              },
            ],
          },
          AuthUserAttrs: {
            enabled: true,
          },
        },
        InternalPolicies: [
          {
            Statement: [
              {
                Sid: "OwnerAccess",
                Effect: "Allow",
                Action: [
                  "manager:Utilize",
                  "manager:Read",
                  "manager:Update",
                  "manager:Delete",
                ],
                Resource: `manager:object:${data.tenantId}`,
                Condition: accountName
                  ? {
                      StringEquals: {
                        "user:email": accountName,
                      },
                    }
                  : {},
              },
            ],
          },
        ],
        Addons: {},
        Config: {
          ENV_NAME: data.tenantId,
          REQUIRE_TOKEN: data.token.required,
          TARGET_ROLE_ARN: data.account.targetRole,
          AWS_REGION: "eu-central-1",
          AWS_ACCOUNT_ID: data.account.awsAccountId,
          AZURE_TENANT: data.token.signer.join(";"),
          AWS_SFTP_SERVER_REGION: "bar",
          OIDC_ISSUER: data.token.issuer.join(";"),
          OIDC_AUDIENCE: data.token.audience.join(";"),
          OIDC_APPID: data.token.appId.join(";"),
          MAX_TOKEN_AGE:
            data.token.age.type === "days"
              ? data.token.age.value * 24
              : data.token.age.value,
          VERIFY_TOKEN_SIGNATURE: data.token.signature,
        },
      };

      const result: {
        data: string | null;
        error: null | string;
      } = await createTenant(newData, msalInstance);

      if (result?.data) {
        navigate("/");
        message.success({
          content: result.data,
          duration: 3,
        });
      }

      if (result.error) {
        setNotification({
          mode: EModes.Error,
          title: result.error,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const addButtons = [
    <Link to="/" key="1">
      <Button>Cancel</Button>
    </Link>,
    <Button onClick={handleSave} key="submit" htmlType="submit" type="primary">
      Save
    </Button>,
  ];

  return (
    <div className="add-tenant">
      <PageHeader
        title="Add tenant"
        className="breadcrumbs"
        breadcrumb={{
          routes: addTenantBreadcrumbs,
          itemRender,
        }}
        extra={addButtons}
      />
      <div className="add-tenant-body">
        {isLoading ? (
          <Spinner />
        ) : (
          <Form
            name="basic"
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
          >
            <GeneralForm data={data} setData={setData} />
            <TokenForm data={data} setData={setData} />
            <AccountForm data={data} setData={setData} />
          </Form>
        )}
      </div>
    </div>
  );
};

export default memo(AddTenant);
