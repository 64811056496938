import { $host } from "./index";
import { AxiosError } from "axios";
import { getHeaders, handleRequestError } from "../helpers";
import { ICompletedTenant } from "../pages/AddTenant/types";
import { IMsalContext } from "@azure/msal-react";
import { getAccessToken } from "../config/token";

export const createTenant = async (
  tenant: ICompletedTenant,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    await $host.post("tenants/", tenant, {
      headers: getHeaders(token),
    });

    return {
      error: null,
      data: "Tenant has been successfully created",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const deleteTenantRequest = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    await $host.delete(`tenants/${id}`, {
      headers: getHeaders(token),
    });

    return {
      error: null,
      data: "Tenant was deleted successfully",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const updateTenant = async (
  tenant: ICompletedTenant,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    await $host.put(`tenants/${tenant.EntityName}`, tenant, {
      headers: getHeaders(token),
    });

    return {
      error: null,
      data: "Tenant has been successfully updated",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};
