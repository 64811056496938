import { memo } from "react";
import { Routes, Route } from "react-router-dom";

import AddTenant from "../../pages/AddTenant";
import Main from "../../pages/Main";
import Tenant from "../../pages/Tenant";
import Tenants from "../../pages/Tenants";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Tenants />}></Route>
      <Route path="/home" element={<Main />}></Route>
      <Route path="/create" element={<AddTenant />}></Route>
      <Route path="/:id" element={<Tenant />}></Route>
    </Routes>
  );
}

export default memo(AppRoutes);
