import {
  createPolicy,
  deletePolicy,
  loadPolicies,
  updatePolicy,
} from "./actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EModes, INotification, IPolicyEntity } from "./types";
import { v4 as uuidv4 } from "uuid";
import { IPolicyPlugin } from "../../pages/Tenant/types";

const initialState: {
  isLoaded: boolean;
  data: { [id: string]: IPolicyEntity };
  notification: null | INotification;
} = {
  isLoaded: false,
  data: {},
  notification: null,
};

const policiesSlice = createSlice({
  name: "Policies",
  initialState,
  reducers: {
    deleteNotification: state => {
      state.notification = null;
    },
  },
  extraReducers: {
    [loadPolicies.rejected.toString()]: state => {
      state.isLoaded = true;
    },
    [loadPolicies.pending.toString()]: (state: { isLoaded: boolean }): void => {
      state.isLoaded = false;
    },
    [loadPolicies.fulfilled.toString()]: (
      state: {
        isLoaded: boolean;
        data: { [id: string]: IPolicyEntity };
        notification: null | INotification;
      },
      action: PayloadAction<IPolicyPlugin>,
    ): void => {
      if (action.payload.AuthPolicyEngine?.policies) {
        action.payload.AuthPolicyEngine.policies.forEach(item => {
          state.data[item.Id] = {
            ...item,
            Statement: item.Statement.map(statement => ({
              ...statement,
              Id: uuidv4(),
            })),
          };
        });
      }
      state.isLoaded = true;
    },
    [deletePolicy.fulfilled.toString()]: (
      state: {
        isLoaded: boolean;
        data: { [id: string]: IPolicyEntity };
        notification: null | INotification;
      },
      action: PayloadAction<string>,
    ): void => {
      for (const prop in state.data) {
        if (prop === action.payload) {
          delete state.data[prop];
        }
      }

      state.isLoaded = true;

      state.notification = {
        mode: EModes.Success,
        title: `Policy "${action.payload}" deleted succefully`,
      };
    },
    [deletePolicy.pending.toString()]: (state): void => {
      state.isLoaded = false;
    },
    [createPolicy.fulfilled.toString()]: (
      state: {
        isLoaded: boolean;
        data: { [id: string]: IPolicyEntity };
        notification: null | INotification;
      },
      action: PayloadAction<IPolicyEntity>,
    ) => {
      state.data[action.payload.Id] = {
        ...action.payload,
        Statement: action.payload.Statement.map(item => ({
          ...item,
          Id: uuidv4(),
        })),
      };
      state.notification = {
        mode: EModes.Success,
        title: `Policy "${action.payload.Id}" added successfully`,
      };
      state.isLoaded = true;
    },
    [createPolicy.pending.toString()]: state => {
      state.isLoaded = false;
    },
    [createPolicy.rejected.toString()]: (
      state: {
        isLoaded: boolean;
        data: { [id: string]: IPolicyEntity };
        notification: null | INotification;
      },
      action: PayloadAction<{
        title: string;
        text: string;
        connection?: boolean;
      }>,
    ) => {
      state.notification = {
        subTitle: action.payload.text,
        mode: EModes.Error,
        title: action.payload.title,
        connection: action.payload.connection,
      };
      state.isLoaded = true;
    },
    [updatePolicy.fulfilled.toString()]: (
      state,
      action: PayloadAction<IPolicyEntity>,
    ) => {
      state.data[action.payload.Id] = {
        ...action.payload,
        Statement: action.payload.Statement.map(item => ({
          ...item,
          Id: uuidv4(),
        })),
      };
      state.notification = {
        mode: EModes.Success,
        title: `Policy "${action.payload.Id}" updated successfully`,
      };
    },
    [updatePolicy.rejected.toString()]: (
      state,
      action: PayloadAction<{
        title: string;
        text: string;
        connection?: boolean;
      }>,
    ) => {
      state.notification = {
        subTitle: action.payload.text,
        mode: EModes.Error,
        title: action.payload.title,
        connection: action.payload.connection,
      };
      state.isLoaded = true;
    },
  },
});

export const { deleteNotification } = policiesSlice.actions;

export default policiesSlice.reducer;
