import React, { useCallback, useMemo, useState } from "react";
import { QuestionOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu, Modal } from "antd";
import { mailToProductOwner, mailToSupport } from "../../const";

import "./style.scss";

const SupportMenu: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const supportItems = useMemo(
    () => (
      <Menu
        items={[
          {
            label: <div onClick={showModal}>Version</div>,
            className: "header-support-item",
            key: "1",
          },
          {
            label: (
              <a href={mailToSupport} target="_blank" rel="noreferrer">
                Contact support
              </a>
            ),
            className: "header-support-item",
            key: "2",
          },
          {
            label: (
              <a href={mailToProductOwner} target="_blank" rel="noreferrer">
                Contact Product Owner
              </a>
            ),
            className: "header-support-item",
            key: "3",
          },
        ]}
      />
    ),
    [showModal],
  );

  return (
    <>
      <Modal
        title="Version"
        visible={isModalVisible}
        onCancel={handleCancel}
        className="version-modal"
        okButtonProps={{
          className: "okButton",
        }}
        cancelButtonProps={{
          className: "cancelButton",
        }}
      >
        <p>
          You have installed eArchieve application version 1.5.00.17261. Last
          update: 02.07.2022.
        </p>
      </Modal>
      <Dropdown
        overlay={supportItems}
        className="header-support-dropdown"
        trigger={["click"]}
      >
        <div>
          <Space className="header-support-button">
            <QuestionOutlined />
          </Space>
        </div>
      </Dropdown>
    </>
  );
};

export default SupportMenu;
