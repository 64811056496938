import React, { memo } from "react";
import { Card, Descriptions, Input } from "antd";

import { ITenant } from "../../pages/Tenants/types";

import "./style.scss";

const AccountForm = ({
  data,
  setData,
}: {
  data: ITenant;
  setData: (value: ITenant) => void;
}) => {
  const handleAccountSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      account: {
        ...data.account,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <Card
      title="Account settings"
      className="tenant-account-form"
      bodyStyle={{
        paddingLeft: 130,
      }}
    >
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="AWS account ID"
          labelStyle={{
            width: 170,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Input
            type="number"
            name="awsAccountId"
            onChange={handleAccountSettings}
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="Target IAM role ARN"
          labelStyle={{
            width: 170,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Input name="targetRole" onChange={handleAccountSettings} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default memo(AccountForm);
