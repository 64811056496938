import { memo, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, message, PageHeader, Popover, Tabs } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import useFetch from "../../hooks/useFetch";
import { url } from "../../http";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { tenantBreadcrumbs, tenantPolicyBreadcrumbs } from "../../const";
import { IPluginsList, ITenantProps } from "./types";
import { deleteTenantRequest } from "../../http/userApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { deletePolicy } from "../../store/Policies/actions";

import Policies from "../Policies";
import Spinner from "../../common/Spinner";
import Button from "../../common/Button";
import General from "../../components/General";
import PluginsComponent from "../../components/PluginsComponent";
import TokenConfig from "../../components/TokenConfig";
import AuditLog from "../../components/AuditLog";
import AddPolicy from "../AddPolicy";
import EditPolicy from "../EditPolicy";

import "./style.scss";

const Tenant = () => {
  const msalInstance = useMsal();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [visible, setVisible] = useState(false);
  const [pluginsList, setPluginsList] = useState<IPluginsList[]>([]);
  const [visiblePolicy, setPolicyVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabKey, setTabKey] = useState<string>("1");
  const navigate = useNavigate();
  const [policyPage, setPolicyPage] = useState<{
    title: string;
    policyId?: string;
  }>({ title: "list" });

  const { data, isLoading } = useFetch<ITenantProps>(
    `${url}tenants/${id}`,
    msalInstance,
  );

  useEffect(() => {
    if (data && data.Plugins) {
      for (const [name, value] of Object.entries(data.Plugins)) {
        if (name.includes("Secrets")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Secrets",
                  label:
                    name === "SecretsIamUser"
                      ? "AWS IAM user"
                      : name === "SecretsIamSession"
                      ? "AWS IAM Session"
                      : "",
                  descr:
                    name === "SecretsIamUser"
                      ? "Allow provisioning of AWS IAM User (for long-term credentials). If needed, the user will have configured IAM Role and will be tagged with the requesting user attributes."
                      : name === "SecretsIamSession"
                      ? "Allow provisioning of short-term AWS IAM Session credentials. The session will assume configured IAM Role and will be tagged with the user attributes if needed."
                      : "",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Secrets",
                  label:
                    name === "SecretsIamUser"
                      ? "AWS IAM user"
                      : name === "SecretsIamSession"
                      ? "AWS IAM Session"
                      : "",
                  descr:
                    name === "SecretsIamUser"
                      ? "Allow provisioning of AWS IAM User (for long-term credentials). If needed, the user will have configured IAM Role and will be tagged with the requesting user attributes."
                      : name === "SecretsIamSession"
                      ? "Allow provisioning of short-term AWS IAM Session credentials. The session will assume configured IAM Role and will be tagged with the user attributes if needed."
                      : "",
                },
              ];
            }
          });
        } else if (name.includes("UserAttrs")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "User attributes",
                  label: name === "AuthUserAttrs" ? "Azure AD user" : "",
                  descr:
                    name === "AuthUserAttrs"
                      ? "Azure AD user attributes such as manager, department, etc."
                      : "",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "User attributes",
                  label: name === "AuthUserAttrs" ? "Azure AD user" : "",
                  descr:
                    name === "AuthUserAttrs"
                      ? "Azure AD user attributes such as manager, department, etc."
                      : "",
                },
              ];
            }
          });
        } else if (name.includes("Data")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Data attributes",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Data attributes",
                },
              ];
            }
          });
        } else if (name.includes("Auth")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Authorization",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Authorization",
                },
              ];
            }
          });
        }
      }
    }
  }, [data, data?.Plugins]);

  const handleVisiblePolicyChange = (newVisible: boolean) => {
    setPolicyVisible(newVisible);
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  const switchToPolicyList = () => setPolicyPage({ title: "list" });

  const handleDeletePolicy = useCallback(() => {
    if (data && policyPage.policyId) {
      dispatch(
        deletePolicy({ Id: policyPage.policyId, tenant: data, msalInstance }),
      ).then(() => {
        setPolicyPage({ title: "list", policyId: "" });
        navigate(`/${data.EntityName}`);
      });
    } else {
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data, navigate, policyPage.policyId, msalInstance]);

  const itemRender = (route: Route) => {
    if (route.path === "/:id") {
      return (
        <div className="tenant-name-breadcrumb" onClick={switchToPolicyList}>
          {data?.EntityName}
        </div>
      );
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success({
      content: "Tenant link is copied",
      duration: 1,
    });
  };

  const hidePopover = useCallback(() => {
    setVisible(false);
  }, []);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const contentPolicy = (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure you want to delete this policy?</span>
      </div>
      <div className="popup-buttons">
        <Button
          type="default"
          size="small"
          className="popup-btn"
          onClick={hidePopover}
        >
          No
        </Button>
        <Button type="primary" size="small" onClick={handleDeletePolicy}>
          Yes
        </Button>
      </div>
    </div>
  );

  const addButtons = [
    <Link to="/" key="1">
      <Button>Cancel</Button>
    </Link>,
    <Button form="policyForm" key="submit" htmlType="submit" type="primary">
      Save
    </Button>,
  ];

  const editButtons = [
    <Popover
      content={contentPolicy}
      trigger="click"
      visible={visiblePolicy}
      onVisibleChange={handleVisiblePolicyChange}
    >
      <Button type="link" size="middle" icon={<DeleteOutlined />}>
        Delete policy
      </Button>
    </Popover>,
    ...addButtons,
  ];

  const deleteTenant = useCallback(async () => {
    setLoading(true);

    if (id) {
      const result: {
        data: string | null;
        error: null | string;
      } = await deleteTenantRequest(id, msalInstance);

      if (result.data) {
        navigate("/");
        message.success({
          content: result.data,
          duration: 2,
        });
      }

      if (result.error) {
        message.error({
          content: "Something wrong",
          duration: 2,
        });
      }
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, msalInstance]);

  const switchToAddPolicy = () => setPolicyPage({ title: "add" });

  const content = (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure you want to delete this tenant?</span>
      </div>
      <div className="popup-buttons">
        <Button
          type="default"
          onClick={hidePopover}
          size="small"
          className="popup-btn"
        >
          No
        </Button>
        <Button
          type="primary"
          size="small"
          loading={loading}
          onClick={deleteTenant}
        >
          Yes
        </Button>
      </div>
    </div>
  );

  return (
    <div className="tenant-page">
      {isLoading ? (
        <Spinner className="spinner-tenant" />
      ) : (
        <>
          <PageHeader
            title={
              policyPage.title === "list"
                ? `Tenant: ${data?.EntityName}`
                : policyPage.title === "edit"
                ? `Policy: ${policyPage.policyId}`
                : "Add policy"
            }
            className="breadcrumbs"
            breadcrumb={{
              routes:
                policyPage.title === "list"
                  ? tenantBreadcrumbs
                  : tenantPolicyBreadcrumbs,
              itemRender,
            }}
            extra={
              policyPage.title === "list" ? (
                <div className="header-buttons">
                  <Popover
                    content={content}
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button
                      className="delete-btn"
                      size="large"
                      type="link"
                      icon={<DeleteOutlined />}
                    >
                      Delete tenant
                    </Button>
                  </Popover>
                  <Button
                    size="large"
                    onClick={handleCopy}
                    icon={<CopyOutlined />}
                  >
                    Copy link
                  </Button>
                </div>
              ) : policyPage.title === "edit" ? (
                editButtons
              ) : (
                addButtons
              )
            }
            footer={
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <Tabs.TabPane tab="General" key="1" />
                <Tabs.TabPane tab="Authentication" key="2" />
                <Tabs.TabPane tab="Attribute sources" key="3" />
                <Tabs.TabPane tab="Access policies" key="4" />
                <Tabs.TabPane tab="Targets" key="5" />
                <Tabs.TabPane tab="Log" key="6" />
              </Tabs>
            }
          />
        </>
      )}
      {data ? (
        tabKey === "1" ? (
          <General
            name={data.EntityName}
            descr={data.Description}
            plugins={data.Plugins}
            config={data.Config}
            internalPolicies={data.InternalPolicies}
          />
        ) : tabKey === "2" ? (
          <>
            <TokenConfig
              name={data.EntityName}
              descr={data.Description}
              plugins={data.Plugins}
              config={data.Config}
              internalPolicies={data.InternalPolicies}
            />
          </>
        ) : tabKey === "3" ? (
          <PluginsComponent
            tenant={data}
            title="Attribute sources"
            data={data}
            pluginsList={pluginsList.filter(
              item =>
                item.pluginType === "User attributes" ||
                item.pluginType === "Data attributes",
            )}
            setPluginsList={setPluginsList}
          />
        ) : tabKey === "4" ? (
          <>
            {policyPage.title === "list" ? (
              <Card
                title="Policies"
                className="policies-add-card"
                extra={
                  <Button
                    icon={<PlusOutlined />}
                    onClick={switchToAddPolicy}
                    type="primary"
                    size="large"
                  >
                    Add policy
                  </Button>
                }
                bodyStyle={{
                  display: "none",
                }}
              />
            ) : null}
            {policyPage.title === "list" ? (
              <Policies
                tenant={data}
                tenantId={data.EntityName}
                setPolicyPage={setPolicyPage}
              />
            ) : policyPage.title === "edit" && policyPage.policyId ? (
              <EditPolicy
                policyPage={policyPage}
                tenant={data}
                id={policyPage.policyId}
                setPolicyPage={setPolicyPage}
              />
            ) : (
              <AddPolicy tenant={data} setPolicyPage={setPolicyPage} />
            )}
          </>
        ) : tabKey === "5" ? (
          <PluginsComponent
            tenant={data}
            title="Targets"
            data={data}
            pluginsList={pluginsList.filter(
              item => item.pluginType === "Secrets",
            )}
            setPluginsList={setPluginsList}
          />
        ) : (
          <AuditLog />
        )
      ) : null}
    </div>
  );
};

export default memo(Tenant);
