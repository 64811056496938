import axios, { AxiosError } from "axios";
import { isDevelopment, OperatorOptions } from "../const";
import { ICondition, IStatement } from "../pages/Policies/types";
import { IPlugins, IPluginsList } from "../pages/Tenant/types";
import { IStatementEntity } from "../store/Policies/types";

export const getHeaders = (token: string | undefined) => ({
  "x-api-key": process.env.REACT_APP_API_ACCESS_KEY || "",
  Authorization:
    !isDevelopment && token
      ? `Bearer ${token}`
      : `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  "Content-Type": "application/json",
});

export const convertBytes = (bytes: number) => {
  let result = "";

  const kb = bytes / 1024,
    mb = kb / 1024,
    gb = mb / 1024;

  if (kb <= 1024) {
    result = kb.toFixed(3) + " KB";
  } else if (kb >= 1024 && mb <= 1024) {
    result = mb.toFixed(3) + " MB";
  } else if (mb >= 1024 && gb <= 1024) {
    result = gb.toFixed(3) + " GB";
  }

  return result;
};

export const formatDateTime = (number: number) => {
  const timestamp = new Date(number * 1000);
  const dtFormat = new Intl.DateTimeFormat("en-GB", {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
  });

  return dtFormat.format(new Date(timestamp));
};

export const handleRequestError = (error: AxiosError | { message: string }) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 422) {
      return {
        error: (
          error.response.data as {
            detail: Array<{
              msg: string;
            }>;
          }
        ).detail[0].msg,
        data: null,
      };
    } else {
      return {
        error: (error.response?.data as { detail: string }).detail,
        data: null,
      };
    }
  } else {
    return {
      data: null,
      error: (error as { message: string }).message,
    };
  }
};

export const generateStatementsFromEntity = (
  statements: IStatementEntity[],
) => {
  return statements.map(stateItem => ({
    ...stateItem,
    Condition: Object.keys(stateItem.Condition || {}).reduce(
      (acc: Array<ICondition>, key: string, index) => {
        const conditionsOfCondition = Object.keys(stateItem.Condition[key]);
        conditionsOfCondition.forEach((sub, subIndex) => {
          const Format = key.includes("Numeric") ? "Numeric" : "String";
          acc.push({
            Subject: sub.split(":").length > 1 ? sub.split(":")[0] : "-",
            Operator: OperatorOptions(Format).find(format =>
              key.includes(format.value),
            )?.value,
            Format,
            ApplyTo: key.split(":").length > 1 ? key.split(":")[0] : "-",
            Value: stateItem.Condition[key][sub],
            Id: `${index}_${subIndex}`,
            IfExists: key.includes("IfExists"),
            IgnoreCase: key.includes("IgnoreCase"),
            Attribute: sub.split(":").length > 1 ? sub.split(":")[1] : "-",
          });
        });
        return acc;
      },
      [],
    ),
  }));
};

export const generateConditions = (
  conditions: ICondition[],
  conditionId: string,
  field: string,
  value?: string | Array<string> | boolean,
): ICondition[] => {
  const generetedConditions = conditions.map(subItem => {
    if (subItem.Id === conditionId) {
      const newFields: {
        Operator?: string;
        ApplyTo?: string;
        IgnoreCase?: boolean;
      } = {};
      if (field === "Format") {
        if (value === "Numeric") {
          newFields.IgnoreCase = false;
        }
      }
      if ((field === "Operator" && value === "Like") || value === "NotLike") {
        newFields.IgnoreCase = false;
      }

      return {
        ...subItem,
        [field]: value,
        ...newFields,
      };
    } else {
      return subItem;
    }
  });

  return Array.from(new Set(generetedConditions));
};

export const getCondition = (
  statementId: string,
  conditionId: string,
  statements: IStatement[],
) => {
  return (
    statements
      .find((statement: IStatement) => statement.Id === statementId)
      ?.Condition.find(
        (condition: ICondition) => condition.Id === conditionId,
      ) || statements[0].Condition[0]
  );
};

export const generateStatementsToEntity = (
  statements: IStatement[],
): IStatementEntity[] => {
  return statements.map(item => ({
    ...item,
    Condition: item.Condition.reduce(
      (
        acc: {
          [index: string]: {
            [index: string]: Array<string>;
          };
        },
        subItem,
      ) => {
        let key = "";
        if (subItem.ApplyTo && subItem.ApplyTo !== "Single value") {
          key = key.concat(`${subItem.ApplyTo}:`);
        }
        if (subItem.Format) {
          key = key.concat(subItem.Format);
        }
        if (subItem.Operator) {
          key = key.concat(subItem.Operator);
        }
        if (subItem.IgnoreCase) {
          key = key.concat("IgnoreCase");
        }
        if (subItem.IfExists) {
          key = key.concat("IfExists");
        }
        const subject = `${subItem.Subject as string}:${subItem.Attribute}`;
        if (!acc[key]) {
          acc[key] = {
            [subject]: subItem.Value,
          };
        } else {
          acc[key][subject] = acc[key][subject]
            ? acc[key][subject].concat(subItem.Value)
            : subItem.Value;
        }
        return acc;
      },
      {},
    ),
  }));
};

export const generatePlugins = (plugins: IPlugins) => {
  const pluginsList: IPluginsList[] = [];

  for (const [name, value] of Object.entries(plugins)) {
    if (name.includes("Secrets")) {
      pluginsList.push({
        name: name,
        enabled: value.enabled,
        pluginType: "Secrets",
      });
    } else if (name.includes("UserAttrs")) {
      pluginsList.push({
        name: name,
        enabled: value.enabled,
        pluginType: "User attributes",
      });
    } else if (name.includes("Data")) {
      pluginsList.push({
        name: name,
        enabled: value.enabled,
        pluginType: "Data attributes",
      });
    } else if (name.includes("Auth")) {
      pluginsList.push({
        name: name,
        enabled: value.enabled,
        pluginType: "Authorization",
      });
    }
  }

  return pluginsList;
};
