import React, { memo, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Table } from "antd";

import useFetch from "../../hooks/useFetch";
import { TENANT_TABLE_COLUMNS } from "../../const";
import { ITenantDisplay } from "../AddTenant/types";
import { url } from "../../http";

import Spinner from "../../common/Spinner";
import ListPageHeader from "../../components/ListPageHeader";

import "./style.scss";

const Tenants: React.FC = () => {
  const msalInstance = useMsal();

  const { data, isLoading } = useFetch<
    {
      EntityName: string;
      Description: string;
      LastModified: string;
      PluginsNumber: number;
      PoliciesNumber: number;
    }[]
  >(`${url}tenants/`, msalInstance);

  const [tenants, setTenants] = useState<ITenantDisplay[]>([]);

  useEffect(() => {
    if (data) {
      setTenants(
        data.map(item => ({
          name: item.EntityName,
          plugins: item.PluginsNumber,
          policies: item.PoliciesNumber,
          lastUpdated: item.LastModified,
        })),
      );
    }
  }, [data]);

  const [search, setSearch] = useState("");

  const [searchedTenants, setSearchedTenants] = useState<ITenantDisplay[]>([]);

  useEffect(
    () =>
      setSearchedTenants(
        tenants.filter(item =>
          item.name.toLowerCase().includes((search || "").toLowerCase()),
        ),
      ),
    [search, tenants],
  );

  const generatedTenants = useMemo(
    () =>
      searchedTenants.map(item => ({
        key: item.name,
        name: item.name,
        plugins: item.plugins,
        policies: item.policies,
        lastUpdated: item.lastUpdated.replace(
          /^(\d+)-(\d+)-(\d+)\D.+$/,
          "$3.$2.$1",
        ),
      })),
    [searchedTenants],
  );

  return (
    <div className="tenants-list">
      <ListPageHeader setSearchItems={setSearch} />
      <div className="tenants-table">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Table
              dataSource={generatedTenants}
              columns={TENANT_TABLE_COLUMNS}
              pagination={{
                pageSize: 5,
                pageSizeOptions: [5, 10],
              }}
              className="table"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Tenants);
