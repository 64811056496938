import { useEffect, memo, useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Pagination, Modal, Space, Spin } from "antd";
import { loadPolicies } from "../../store/Policies/actions";
import { AppDispatch } from "../../store";
import {
  arePoliciesLoaded,
  getAllPolicies,
  getNotification,
} from "../../store/Policies/selectors";
import { deleteNotification } from "../../store/Policies/slice";
import { ITenantProps } from "../Tenant/types";

import PolicyCard from "../../components/Policies/PolicyCard";

import "./style.scss";

const Policies = ({
  tenant,
  tenantId,
  setPolicyPage,
}: {
  tenant: ITenantProps;
  tenantId: string;
  setPolicyPage: (value: { title: string; policyId?: string }) => void;
}) => {
  const msalInstance = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const policies = useSelector(getAllPolicies);
  const isLoaded = useSelector(arePoliciesLoaded);
  const notification = useSelector(getNotification);

  useEffect(() => {
    const secondsToGo = 5;
    if (notification) {
      const modal = Modal[notification.mode]({
        title: notification.title,
        content: notification.subTitle,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        maskClosable: true,
        afterClose() {
          dispatch(deleteNotification());
          modal.destroy();
        },
      });
      setTimeout(() => {
        dispatch(deleteNotification());
        modal.destroy();
      }, secondsToGo * 1000);
    }
  }, [notification, dispatch]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);

  useEffect(() => {
    policies.length < perPage * currentPage && setCurrentPage(1);
  }, [policies, perPage, currentPage]);

  const indexLastPolicy = currentPage * perPage;
  const indexFirstPolicy = indexLastPolicy - perPage;
  const currentPolicies = policies.slice(indexFirstPolicy, indexLastPolicy);

  const handlePagination = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    if (!isLoaded && !policies.length) {
      dispatch(loadPolicies({ tenantId, msalInstance }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoaded, policies, tenantId, msalInstance]);

  return (
    <div className="policies">
      {isLoaded ? (
        currentPolicies.length ? (
          <>
            {currentPolicies.map(item => {
              return (
                <PolicyCard
                  tenant={tenant}
                  policy={item}
                  key={item.Id}
                  setPolicyPage={setPolicyPage}
                />
              );
            })}
            <Pagination
              current={currentPage}
              showSizeChanger
              pageSize={5}
              defaultCurrent={1}
              total={policies.length}
              onChange={handlePagination}
            />
          </>
        ) : (
          <div className="empty-wrapper">
            <Empty description="No policies yet" />
          </div>
        )
      ) : (
        <div className="spinner-wrapper">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      )}
    </div>
  );
};

export default memo(Policies);
