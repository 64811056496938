import {
  Descriptions,
  Empty,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
} from "antd";
import { ChangeEvent, memo } from "react";
import { IPluginsList } from "../../../pages/Tenant/types";

type PluginFields = {
  SecretsIamSession: {
    MAX_AD_GROUPS: number;
    SKIP_SESSION_TAGS: boolean;
    pattern: string;
  };
  SecretsIamUser: {
    IAM_GROUP_TARGET_PREFIX: string;
    IAM_USER_SESSION_LIFETIME: string;
    MAX_AD_GROUPS: number;
    SESSION_MODE: boolean;
    pattern: string;
  };
};

interface IProps {
  selected: IPluginsList;
  pluginsData: PluginFields;
  setPluginsData: (value: PluginFields) => void;
}

const Details = ({ selected, pluginsData, setPluginsData }: IProps) => {
  const handleChangeSessionTags = (e: RadioChangeEvent) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamSession: {
        ...pluginsData.SecretsIamSession,
        SKIP_SESSION_TAGS: e.target.value === "Enabled" ? true : false,
      },
    });
  };

  const handleChangePattern = (e: ChangeEvent<HTMLInputElement>) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamSession: {
        ...pluginsData.SecretsIamSession,
        pattern: e.target.value,
      },
    });
  };

  const handleChangeUserPattern = (e: ChangeEvent<HTMLInputElement>) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamUser: {
        ...pluginsData.SecretsIamUser,
        pattern: e.target.value,
      },
    });
  };

  const handleChangeMaxGroups = (value: number | null) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamSession: {
        ...pluginsData.SecretsIamSession,
        MAX_AD_GROUPS: value ? value : 0,
      },
    });
  };

  const handleChangeUserMaxGroups = (value: number | null) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamUser: {
        ...pluginsData.SecretsIamUser,
        MAX_AD_GROUPS: value ? value : 0,
      },
    });
  };

  const handleChangeSessionMode = (e: RadioChangeEvent) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamUser: {
        ...pluginsData.SecretsIamUser,
        SESSION_MODE: e.target.value,
      },
    });
  };

  const handleChangeTargetPrefix = (e: ChangeEvent<HTMLInputElement>) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamUser: {
        ...pluginsData.SecretsIamUser,
        IAM_GROUP_TARGET_PREFIX: e.target.value,
      },
    });
  };

  const handleChangeSessionLifetime = (e: ChangeEvent<HTMLInputElement>) => {
    setPluginsData({
      ...pluginsData,
      SecretsIamUser: {
        ...pluginsData.SecretsIamUser,
        IAM_USER_SESSION_LIFETIME: e.target.value,
      },
    });
  };

  return (
    <>
      {selected.name === "SecretsIamSession" ? (
        <Descriptions column={1} className="details-wrapper">
          <Descriptions.Item
            className="details-descr"
            label="Max AD groups"
            labelStyle={{
              width: 170,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <InputNumber
              value={pluginsData.SecretsIamSession.MAX_AD_GROUPS}
              onChange={handleChangeMaxGroups}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className="details-descr"
            label="Skip session tags"
            labelStyle={{
              width: 170,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <Radio.Group
              defaultValue={
                pluginsData.SecretsIamSession.SKIP_SESSION_TAGS
                  ? "Enabled"
                  : "Disabled"
              }
              value={
                pluginsData.SecretsIamSession.SKIP_SESSION_TAGS
                  ? "Enabled"
                  : "Disabled"
              }
              onChange={handleChangeSessionTags}
              buttonStyle="solid"
            >
              <Radio.Button value="Enabled">Enabled</Radio.Button>
              <Radio.Button value="Disabled">Disabled</Radio.Button>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item
            className="details-descr"
            label="Pattern"
            labelStyle={{
              width: 170,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <Input
              value={pluginsData.SecretsIamSession.pattern}
              onChange={handleChangePattern}
            />
          </Descriptions.Item>
        </Descriptions>
      ) : selected.name === "SecretsIamUser" ? (
        <Descriptions column={1} className="details-wrapper">
          <Descriptions.Item
            className="details-descr"
            label="MAX_AD_GROUPS"
            labelStyle={{
              width: 230,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <InputNumber
              value={pluginsData.SecretsIamUser.MAX_AD_GROUPS}
              onChange={handleChangeUserMaxGroups}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className="details-descr"
            label="SESSION_MODE"
            labelStyle={{
              width: 230,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <Radio.Group
              defaultValue={
                pluginsData.SecretsIamUser.SESSION_MODE ? "Enabled" : "Disabled"
              }
              value={
                pluginsData.SecretsIamUser.SESSION_MODE ? "Enabled" : "Disabled"
              }
              onChange={handleChangeSessionMode}
              buttonStyle="solid"
            >
              <Radio.Button value="Enabled">Enabled</Radio.Button>
              <Radio.Button value="Disabled">Disabled</Radio.Button>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item
            className="details-descr"
            label="Pattern"
            labelStyle={{
              width: 230,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <Input
              value={pluginsData.SecretsIamUser.pattern}
              onChange={handleChangeUserPattern}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className="details-descr"
            label="IAM_GROUP_TARGET_PREFIX"
            labelStyle={{
              width: 230,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <Input
              value={pluginsData.SecretsIamUser.IAM_GROUP_TARGET_PREFIX}
              onChange={handleChangeTargetPrefix}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className="details-descr"
            label="IAM_USER_SESSION_LIFETIME"
            labelStyle={{
              width: 230,
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            <Input
              value={pluginsData.SecretsIamUser.IAM_USER_SESSION_LIFETIME}
              onChange={handleChangeSessionLifetime}
            />
          </Descriptions.Item>
        </Descriptions>
      ) : selected.name === "AuthUserAttrs" ? (
        <Empty />
      ) : selected.name === "AuthAllowAll" ? (
        <Empty />
      ) : (
        <Empty />
      )}
    </>
  );
};

export default memo(Details);
