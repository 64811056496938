import { Card, Input, InputRef, Table } from "antd";
import { useMemo, useRef, useState } from "react";
import { ILog } from "./types";
import { AUDIT_LOG_COLUMNS, mockLog } from "../../const";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";

import "./style.scss";

const AuditLog = () => {
  const [logs, setLogs] = useState<{ Logs: ILog[] }>();
  const [loading, setLoading] = useState<boolean>(false);
  const generateRef = useRef<InputRef>(null);

  const handleShowAuditLog = () => {
    setLoading(true);

    setLogs({
      Logs: mockLog,
    });

    setLoading(false);
  };

  const getDetails = (field: {
    [index: string]: {
      [index: string]: string;
    };
  }) => (
    <div>
      <p>IAM session requested</p>
      {field.added && Object.keys(field.added).length > 0 ? <b>Added:</b> : ""}
      {field.added &&
      Object.keys(field.added).length > 0 &&
      !(typeof field.added === "string" || field.added instanceof String)
        ? Object.keys(field.added).map(key => (
            <p style={{ textIndent: 3 }}>
              {key}
              {field.added[key] ? ': "' + field.added[key] + '"' : ""}
            </p>
          ))
        : ""}
      {field.added &&
      (typeof field.added === "string" || field.added instanceof String) ? (
        <p style={{ textIndent: 3 }}>{field.added}</p>
      ) : (
        ""
      )}
      {field.changed && Object.keys(field.changed).length > 0 ? (
        <b>Changed:</b>
      ) : (
        ""
      )}
      {field.changed &&
      Object.keys(field.changed).length > 0 &&
      !(typeof field.changed === "string" || field.changed instanceof String)
        ? Object.keys(field.changed).map(key => (
            <p style={{ textIndent: 3 }}>
              {key}
              {field.changed[key] ? ': "' + field.changed[key] + '"' : ""}
            </p>
          ))
        : ""}
      {field.changed &&
      (typeof field.changed === "string" || field.changed instanceof String) ? (
        <p style={{ textIndent: 3 }}>{field.changed}</p>
      ) : (
        ""
      )}
      {field.removed && Object.keys(field.removed).length > 0 ? (
        <b>Removed:</b>
      ) : (
        ""
      )}
      {field.removed &&
      Object.keys(field.removed).length > 0 &&
      !(typeof field.removed === "string" || field.removed instanceof String)
        ? Object.keys(field.removed).map(key => (
            <p style={{ textIndent: 3 }}>
              {key}
              {field.removed[key] ? ': "' + field.removed[key] + '"' : ""}
            </p>
          ))
        : ""}
      {field.removed &&
      (typeof field.removed === "string" || field.removed instanceof String) ? (
        <p style={{ textIndent: 3 }}>{field.removed}</p>
      ) : (
        ""
      )}
    </div>
  );

  const auditData = useMemo(
    () =>
      logs?.Logs.map((item, index) => ({
        key: index,
        bucket: item.Bucket,
        dateTime: item.DateTime,
        decision: item.Decision,
        event: item.Event,
        type: item.Type,
        user: item.User,
        comment: item.Changes ? getDetails(item.Changes) : "-",
      })),
    [logs?.Logs],
  );

  return (
    <>
      <Card title="Audit log" className="audit-log">
        <div>
          <div>Count of days</div>
          <div className="input-wrap">
            <Input
              addonAfter="days"
              className="audit-log-input"
              ref={generateRef}
              defaultValue="1"
            />
            <Button type="primary" size="large" onClick={handleShowAuditLog}>
              Generate
            </Button>
          </div>
        </div>
        {logs ? (
          <Table
            dataSource={auditData}
            columns={AUDIT_LOG_COLUMNS}
            className="audit-log-table"
          />
        ) : null}
      </Card>
      {loading ? <Spinner /> : null}
    </>
  );
};

export default AuditLog;
