import { memo, useCallback, useMemo } from "react";

import { Row, Col, Select, Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Tooltip from "../../../../../common/Tooltip";

import { OperatorOptions } from "../../../../../const";

import { ICondition } from "../../../../../pages/AddPolicy/types";

import "./style.scss";

type IProps = {
  condition: ICondition;
  changeCondition: (
    statementId: string,
    conditionId: string,
    name: string,
    value?: Array<string> | string,
  ) => void;
  statementId: string;
  changeCheckBox: (
    statementId: string,
    conditionId: string,
    name: string,
    checked: boolean,
  ) => void;
};

const MeetsCondition = ({
  condition,
  changeCondition,
  statementId,
  changeCheckBox,
}: IProps) => {
  const { Id, Operator, Format, IfExists, IgnoreCase } = condition;
  const operatorOptionsList = useMemo(
    () => (Format ? OperatorOptions(Format) : []),
    [Format],
  );

  const handleChangeOption = useCallback(
    (name: string, value?: string | Array<string>) =>
      changeCondition(statementId, Id, name, value),
    [Id, statementId, changeCondition],
  );

  const handleChangeCheckBox = useCallback(
    (event: CheckboxChangeEvent) =>
      changeCheckBox(
        statementId,
        Id,
        event.target.name as string,
        event.target.checked,
      ),
    [statementId, Id, changeCheckBox],
  );

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item
          colon={false}
          className="condition-operator"
          name={`Operator_${statementId}_${Id}`}
          rules={[
            {
              required: true,
              message: "The field is required.",
            },
            () => ({
              validator(_, value) {
                const operator = operatorOptionsList.find(
                  item => item.value === value,
                )?.disabled;
                if (!operator) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("You can not set this operator within this format"),
                );
              },
            }),
          ]}
          label={
            <Tooltip
              tooltip="Operator refers to the relationship between the specified subject attribute and its value. 
For example, '=' means that the system will grant access to all user groups that exactly match the values entered below.
Note, the values may not specify the exact name, but only part of it, or even individual characters."
              title="Operator"
            />
          }
          initialValue={Operator}
        >
          <Select
            value={Operator}
            className="operator-field"
            onChange={(value: string) => handleChangeOption("Operator", value)}
            placeholder="Select"
            disabled={!Format}
          >
            {operatorOptionsList.map(item => (
              <Select.Option
                value={item.value}
                key={item.id}
                disabled={item.disabled}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col>
        <Tooltip
          tooltip="Check “Ignore case” if uppercase and lowercase for entered values can be ignored.
          If value exists field is not recommended for general cases. Ask your administrator, if you need details."
          title="Optional"
        />

        <Checkbox
          className="checkbox"
          checked={IfExists}
          name="IfExists"
          onChange={handleChangeCheckBox}
          disabled={!Format}
        >
          If value exists
        </Checkbox>
        <Checkbox
          className="checkbox"
          checked={IgnoreCase}
          onChange={handleChangeCheckBox}
          name="IgnoreCase"
          disabled={
            !Format ||
            Format === "Numeric" ||
            Operator === "Like" ||
            Operator === "NotLike"
          }
        >
          Ignore case
        </Checkbox>
      </Col>
    </Row>
  );
};

export default memo(MeetsCondition);
