import { memo, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Card,
  Descriptions,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Switch,
  Tag,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import { IConfigForm, IProps } from "./types.props";
import { initialConfigForm } from "../../const";
import { ICompletedTenant } from "../../pages/AddTenant/types";
import { EModes, INotification } from "../../pages/Tenants/types";
import { updateTenant } from "../../http/userApi";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";
import ListPopup from "../../common/ListPopup";

import "./style.scss";

const TokenConfig = ({
  name,
  descr,
  plugins,
  config,
  internalPolicies,
}: IProps) => {
  const msalInstance = useMsal();
  const [configData, setConfigData] = useState<IConfigForm>(initialConfigForm);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    issuerField: "",
    audienceField: "",
    appIdField: "",
    signerField: "",
  });
  const [edit, setEdit] = useState(false);

  const [notification, setNotification] = useState<INotification | null>(null);

  const switchToEdit = () => setEdit(!edit);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification]);

  useEffect(() => {
    if (config) {
      setConfigData({
        description: descr || "",
        required: config.REQUIRE_TOKEN,
        issuer: config.OIDC_ISSUER ? config.OIDC_ISSUER.split(";") : [],
        audience: config.OIDC_AUDIENCE ? config.OIDC_AUDIENCE.split(";") : [],
        appId: config.OIDC_APPID ? config.OIDC_APPID.split(";") : [],
        signer: config.AZURE_TENANT ? config.AZURE_TENANT.split(";") : [],
        age: {
          value: config.MAX_TOKEN_AGE ? config.MAX_TOKEN_AGE : 0,
          type: "hours",
        },
        signature: config.VERIFY_TOKEN_SIGNATURE,
      });
    }
  }, [config, descr]);

  const handleClose = (title: string, tag: string) => {
    setConfigData({
      ...configData,
      [title]:
        title === "issuer"
          ? configData["issuer"].filter((item: string) => item !== tag)
          : title === "audience"
          ? configData["audience"].filter((item: string) => item !== tag)
          : title === "appId"
          ? configData["appId"].filter((item: string) => item !== tag)
          : configData["signer"].filter((item: string) => item !== tag),
    });
  };

  const handleChangeIssuer = () => {
    if (!inputValue.issuerField) {
      return;
    }

    setConfigData({
      ...configData,
      issuer: [...configData.issuer, inputValue.issuerField],
    });
    setInputValue({
      ...inputValue,
      issuerField: "",
    });
  };

  const handleChangeAgeType = (value: string) => {
    setConfigData({
      ...configData,
      age: {
        ...configData.age,
        type: value,
      },
    });
  };

  const handleChangeAge = (value: number | null) => {
    setConfigData({
      ...configData,
      age: {
        ...configData.age,
        value: value ? value : 0,
      },
    });
  };

  const handleChangeSignature = (event: RadioChangeEvent) => {
    setConfigData({
      ...configData,
      signature: event.target.value === "Enabled",
    });
  };

  const handleChangeAudience = () => {
    if (!inputValue.audienceField) {
      return;
    }

    setConfigData({
      ...configData,
      audience: [...configData.audience, inputValue.audienceField],
    });
    setInputValue({
      ...inputValue,
      audienceField: "",
    });
  };

  const handleChangeSigner = () => {
    if (!inputValue.signerField) {
      return;
    }

    setConfigData({
      ...configData,
      signer: [...configData.signer, inputValue.signerField],
    });
    setInputValue({
      ...inputValue,
      signerField: "",
    });
  };

  const handleChangeAppId = () => {
    if (!inputValue.appIdField) {
      return;
    }

    setConfigData({
      ...configData,
      appId: [...configData.appId, inputValue.appIdField],
    });
    setInputValue({
      ...inputValue,
      appIdField: "",
    });
  };

  const handleSwitchToken = useCallback(
    async (checked: boolean) => {
      try {
        setLoading(true);

        const newData: ICompletedTenant = {
          EntityName: name,
          Description: configData.description,
          Plugins: plugins,
          InternalPolicies: [...internalPolicies],
          Addons: {},
          Config: {
            ...config,
            REQUIRE_TOKEN: checked,
            VERIFY_TOKEN_SIGNATURE: checked,
          },
        };

        const result: {
          data: string | null;
          error: null | string;
        } = await updateTenant(newData, msalInstance);

        if (result?.data) {
          setConfigData({
            ...configData,
            required: checked,
            signature: checked,
          });
          message.success({
            content: result.data,
            duration: 3,
          });
        }

        if (result.error) {
          setNotification({
            mode: EModes.Error,
            title: result.error,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, configData, msalInstance, name, plugins],
  );

  const handleSaveEdit = useCallback(async () => {
    try {
      setLoading(true);

      const newData: ICompletedTenant = {
        EntityName: name,
        Description: configData.description,
        Plugins: plugins,
        InternalPolicies: [...internalPolicies],
        Addons: {},
        Config: {
          ...config,
          REQUIRE_TOKEN: configData.required,
          AZURE_TENANT: configData.signer.join(";"),
          OIDC_ISSUER: configData.issuer.join(";"),
          OIDC_AUDIENCE: configData.audience.join(";"),
          OIDC_APPID: configData.appId.join(";"),
          MAX_TOKEN_AGE:
            configData.age.type === "days"
              ? configData.age.value * 24
              : configData.age.value,
          VERIFY_TOKEN_SIGNATURE: configData.signature,
        },
      };

      const result: {
        data: string | null;
        error: null | string;
      } = await updateTenant(newData, msalInstance);

      if (result?.data) {
        message.success({
          content: result.data,
          duration: 3,
        });
      }

      if (result.error) {
        setNotification({
          mode: EModes.Error,
          title: result.error,
        });
      }
    } finally {
      setLoading(false);
      setEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, name, plugins, config, msalInstance]);

  const selectAfter = (
    <Select
      defaultValue="hours"
      onChange={handleChangeAgeType}
      style={{ width: 88 }}
    >
      <Select.Option value="hours">hours</Select.Option>
      <Select.Option value="days">days</Select.Option>
    </Select>
  );

  return (
    <>
      <Card
        title="Token configuration"
        className="token-card"
        extra={
          <>
            <span>
              {configData.required
                ? "Tocken is required"
                : "Tocken is not required"}
              <Switch
                className="tocken-required-switch"
                onChange={handleSwitchToken}
                loading={loading}
                checked={configData.required}
              />
            </span>
            {edit ? (
              <>
                <Button onClick={switchToEdit} className="cancel-btn">
                  Cancel
                </Button>
                <Button onClick={handleSaveEdit} type="primary">
                  Save
                </Button>
              </>
            ) : (
              <Button onClick={switchToEdit} icon={<EditOutlined />}>
                Edit
              </Button>
            )}
          </>
        }
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Descriptions column={edit ? 1 : 2}>
              <Descriptions.Item
                label="Issuer"
                className={`descr ${edit ? "descr-input" : ""}`}
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!edit ? (
                  configData.issuer.length ? (
                    <ListPopup list={configData.issuer} />
                  ) : (
                    "-"
                  )
                ) : (
                  <>
                    {configData.issuer.length
                      ? configData.issuer.map(item => (
                          <Tag
                            key={item}
                            className="edit-tag"
                            closable
                            onClose={() => handleClose("issuer", item)}
                          >
                            {item}
                          </Tag>
                        ))
                      : null}
                    <Input
                      value={inputValue.issuerField}
                      onBlur={handleChangeIssuer}
                      onPressEnter={handleChangeIssuer}
                      onChange={e =>
                        setInputValue({
                          ...inputValue,
                          issuerField: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Audience"
                className={`descr ${edit ? "descr-input" : ""}`}
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!edit ? (
                  configData.audience.length ? (
                    <ListPopup list={configData.audience} />
                  ) : (
                    "-"
                  )
                ) : (
                  <>
                    {configData.audience.length
                      ? configData.audience.map(item => (
                          <Tag
                            key={item}
                            className="edit-tag"
                            closable
                            onClose={() => handleClose("audience", item)}
                          >
                            {item}
                          </Tag>
                        ))
                      : null}
                    <Input
                      value={inputValue.audienceField}
                      onBlur={handleChangeAudience}
                      onPressEnter={handleChangeAudience}
                      onChange={e =>
                        setInputValue({
                          ...inputValue,
                          audienceField: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={edit ? 1 : 2}>
              <Descriptions.Item
                label="Signer"
                className={`descr ${edit ? "descr-input" : ""}`}
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!edit ? (
                  configData.signer.length ? (
                    <ListPopup list={configData.signer} />
                  ) : (
                    "-"
                  )
                ) : (
                  <>
                    {configData.signer.length
                      ? configData.signer.map(item => (
                          <Tag
                            key={item}
                            className="edit-tag"
                            closable
                            onClose={() => handleClose("signer", item)}
                          >
                            {item}
                          </Tag>
                        ))
                      : null}
                    <Input
                      value={inputValue.signerField}
                      onBlur={handleChangeSigner}
                      onPressEnter={handleChangeSigner}
                      onChange={e =>
                        setInputValue({
                          ...inputValue,
                          signerField: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="App ID"
                className={`descr ${edit ? "descr-input" : ""}`}
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!edit ? (
                  configData.appId.length ? (
                    <ListPopup list={configData.appId} />
                  ) : (
                    "-"
                  )
                ) : (
                  <>
                    {configData.appId.length
                      ? configData.appId.map(item => (
                          <Tag
                            key={item}
                            className="edit-tag"
                            closable
                            onClose={() => handleClose("appId", item)}
                          >
                            {item}
                          </Tag>
                        ))
                      : null}
                    <Input
                      value={inputValue.appIdField}
                      onBlur={handleChangeAppId}
                      onPressEnter={handleChangeAppId}
                      onChange={e =>
                        setInputValue({
                          ...inputValue,
                          appIdField: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={edit ? 1 : 2}>
              <Descriptions.Item
                label="Max age"
                className="descr"
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!edit ? (
                  configData.age.type === "days" ? (
                    configData.age.value * 24
                  ) : (
                    configData.age.value
                  )
                ) : (
                  <InputNumber
                    onChange={handleChangeAge}
                    value={configData.age.value}
                    addonAfter={selectAfter}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Validate signature"
                className="descr"
                labelStyle={{ width: 170, color: "rgba(0, 0, 0, 0.45)" }}
              >
                {!edit ? (
                  configData.signature ? (
                    <div className="activated activated-item">Enabled</div>
                  ) : (
                    <div className="activated-item">Disabled</div>
                  )
                ) : (
                  <Radio.Group
                    defaultValue="Enabled"
                    onChange={handleChangeSignature}
                    buttonStyle="outline"
                  >
                    <Radio.Button value="Enabled">Enabled</Radio.Button>
                    <Radio.Button value="Disabled">Disabled</Radio.Button>
                  </Radio.Group>
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Card>
    </>
  );
};

export default memo(TokenConfig);
