import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { memo } from "react";

import { isDevelopment } from "./const";

import Header from "./components/Header";
import AppRoutes from "./components/Routes";
import Main from "./pages/Main";

function App() {
  return (
    <div className="App">
      <Header />
      {!isDevelopment ? (
        <>
          <AuthenticatedTemplate>
            <AppRoutes />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Main />
          </UnauthenticatedTemplate>
        </>
      ) : (
        <AppRoutes />
      )}
    </div>
  );
}

export default memo(App);
