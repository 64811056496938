import { memo, useState } from "react";
import {
  Card,
  Descriptions,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Tag,
} from "antd";

import { ITenant } from "../../pages/Tenants/types";

import "./style.scss";

const TokenForm = ({
  data,
  setData,
}: {
  data: ITenant;
  setData: (value: ITenant) => void;
}) => {
  const [inputValue, setInputValue] = useState({
    issuerField: "",
    audienceField: "",
    appIdField: "",
    signerField: "",
  });

  const handleClose = (title: string, tag: string) => {
    setData({
      ...data,
      token: {
        ...data.token,
        [title]:
          title === "issuer"
            ? data.token["issuer"].filter((item: string) => item !== tag)
            : title === "audience"
            ? data.token["audience"].filter((item: string) => item !== tag)
            : title === "appId"
            ? data.token["appId"].filter((item: string) => item !== tag)
            : data.token["signer"].filter((item: string) => item !== tag),
      },
    });
  };

  const handleChangeIssuer = () => {
    if (!inputValue.issuerField) {
      return;
    }

    setData({
      ...data,
      token: {
        ...data.token,
        issuer: [...data.token.issuer, inputValue.issuerField],
      },
    });
    setInputValue({
      ...inputValue,
      issuerField: "",
    });
  };

  const handleChangeAudience = () => {
    if (!inputValue.audienceField) {
      return;
    }

    setData({
      ...data,
      token: {
        ...data.token,
        audience: [...data.token.audience, inputValue.audienceField],
      },
    });
    setInputValue({
      ...inputValue,
      audienceField: "",
    });
  };

  const handleChangeAppId = () => {
    if (!inputValue.appIdField) {
      return;
    }

    setData({
      ...data,
      token: {
        ...data.token,
        appId: [...data.token.appId, inputValue.appIdField],
      },
    });
    setInputValue({
      ...inputValue,
      appIdField: "",
    });
  };

  const handleChangeSigner = () => {
    if (!inputValue.signerField) {
      return;
    }

    setData({
      ...data,
      token: {
        ...data.token,
        signer: [...data.token.signer, inputValue.signerField],
      },
    });
    setInputValue({
      ...inputValue,
      signerField: "",
    });
  };

  const handleChangeAge = (value: number | null) => {
    setData({
      ...data,
      token: {
        ...data.token,
        age: {
          ...data.token.age,
          value: value ? value : 0,
        },
      },
    });
  };

  const handleChangeAgeType = (value: string) => {
    setData({
      ...data,
      token: {
        ...data.token,
        age: {
          ...data.token.age,
          type: value,
        },
      },
    });
  };

  const handleChangeRequired = (event: RadioChangeEvent) => {
    setData({
      ...data,
      token: {
        ...data.token,
        required: event.target.value === "Required",
      },
    });
  };

  const handleChangeSignature = (event: RadioChangeEvent) => {
    setData({
      ...data,
      token: {
        ...data.token,
        signature: event.target.value === "Enabled",
      },
    });
  };

  const selectAfter = (
    <Select
      defaultValue="hours"
      onChange={handleChangeAgeType}
      style={{ width: 88 }}
    >
      <Select.Option value="hours">hours</Select.Option>
      <Select.Option value="days">days</Select.Option>
    </Select>
  );

  return (
    <Card
      title="Token configuration"
      className="tenant-token-form"
      bodyStyle={{
        paddingLeft: 135,
      }}
    >
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="Token is required"
          labelStyle={{
            width: 150,
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: "-57px",
          }}
        >
          <Radio.Group
            defaultValue="Required"
            onChange={handleChangeRequired}
            buttonStyle="solid"
          >
            <Radio.Button value="Required">Required</Radio.Button>
            <Radio.Button value="NotRequired">Not required</Radio.Button>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr descr-input"
          label="Issuer"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {data.token.issuer.length
            ? data.token.issuer.map(item => (
                <Tag
                  key={item}
                  className="edit-tag"
                  closable
                  onClose={() => handleClose("issuer", item)}
                >
                  {item}
                </Tag>
              ))
            : null}
          <Input
            value={inputValue.issuerField}
            onBlur={handleChangeIssuer}
            onPressEnter={handleChangeIssuer}
            onChange={e =>
              setInputValue({
                ...inputValue,
                issuerField: e.target.value,
              })
            }
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr descr-input"
          label="Audience"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {data.token.audience.length
            ? data.token.audience.map(item => (
                <Tag
                  key={item}
                  className="edit-tag"
                  closable
                  onClose={() => handleClose("audience", item)}
                >
                  {item}
                </Tag>
              ))
            : null}
          <Input
            value={inputValue.audienceField}
            onBlur={handleChangeAudience}
            onPressEnter={handleChangeAudience}
            onChange={e =>
              setInputValue({
                ...inputValue,
                audienceField: e.target.value,
              })
            }
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr descr-input"
          label="App ID"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {data.token.appId.length
            ? data.token.appId.map(item => (
                <Tag
                  key={item}
                  className="edit-tag"
                  closable
                  onClose={() => handleClose("appId", item)}
                >
                  {item}
                </Tag>
              ))
            : null}
          <Input
            value={inputValue.appIdField}
            onBlur={handleChangeAppId}
            onPressEnter={handleChangeAppId}
            onChange={e =>
              setInputValue({
                ...inputValue,
                appIdField: e.target.value,
              })
            }
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr descr-input"
          label="Signer"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {data.token.signer.length
            ? data.token.signer.map(item => (
                <Tag
                  key={item}
                  className="edit-tag"
                  closable
                  onClose={() => handleClose("signer", item)}
                >
                  {item}
                </Tag>
              ))
            : null}
          <Input
            value={inputValue.signerField}
            onBlur={handleChangeSigner}
            onPressEnter={handleChangeSigner}
            onChange={e =>
              setInputValue({
                ...inputValue,
                signerField: e.target.value,
              })
            }
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="Max age"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <InputNumber onChange={handleChangeAge} addonAfter={selectAfter} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="Validate signature"
          labelStyle={{
            width: 150,
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: "-57px",
          }}
        >
          <Radio.Group
            defaultValue="Enabled"
            onChange={handleChangeSignature}
            buttonStyle="outline"
          >
            <Radio.Button value="Enabled">Enabled</Radio.Button>
            <Radio.Button value="Disabled">Disabled</Radio.Button>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default memo(TokenForm);
