import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  LoginOutlined,
  LogoutOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { MenuProps, Menu, Dropdown, Space } from "antd";

import { isDevelopment } from "../../const";
import { loginRequest } from "../../config/auth";

import SupportMenu from "../SupportMenu";
import Button from "../../common/Button";

import "./style.scss";

const Header: React.FC = () => {
  const [current, setCurrent] = useState("");
  const { instance, accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const accountName: string | undefined = accounts[0]?.name;

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      current !== "list" && setCurrent("list");
    } else {
      setCurrent("");
    }
  }, [location.pathname, current]);

  const menuItemsSwitch: MenuProps["onClick"] = event => {
    setCurrent(event.key);
  };

  const handleLogin = useCallback(() => {
    instance.loginPopup(loginRequest).catch(e => {
      console.log(e);
    });
  }, [instance]);

  const handleLogout = useCallback(() => {
    instance.logoutPopup().catch(e => {
      console.log(e);
    });
  }, [instance]);

  const logoutItem = useMemo(
    () => (
      <Menu
        items={[
          {
            label: (
              <div onClick={handleLogout} className="header-logout-btn">
                <LogoutOutlined className="logout-icon" />
                Log out
              </div>
            ),
            key: "0",
            className: "header-logout",
          },
        ]}
      />
    ),
    [handleLogout],
  );

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" className="logo">
          Access Layer
        </Link>
        <Menu
          onClick={menuItemsSwitch}
          selectedKeys={[current]}
          mode="horizontal"
          disabledOverflow
          className="header-menu"
        >
          <Menu.Item
            key="list"
            icon={<UnorderedListOutlined className="menu-icon" />}
            className="menu-item"
          >
            <Link to="/">Tenants list</Link>
          </Menu.Item>
          <Menu.Item
            key="add"
            icon={<PlusOutlined className="menu-icon" />}
            className="menu-item"
          >
            <Link to="/create">Add tenant</Link>
          </Menu.Item>
        </Menu>
      </div>
      {isDevelopment || isAuthenticated ? (
        <div className="header-right">
          <SupportMenu />
          <Dropdown overlay={logoutItem} trigger={["click"]}>
            <div className="header-account">
              <Space>{accountName}</Space>
            </div>
          </Dropdown>
        </div>
      ) : (
        <Button
          icon={<LoginOutlined />}
          onClick={handleLogin}
          type="primary"
          size="large"
        >
          Login
        </Button>
      )}
    </header>
  );
};

export default memo(Header);
