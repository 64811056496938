import { IMsalContext } from "@azure/msal-react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAccessToken } from "../../config/token";
import { getHeaders } from "../../helpers";
import { url } from "../../http";
import {
  IPlugins,
  IPolicyPlugin,
  ITenantConfig,
  ITenantProps,
} from "../../pages/Tenant/types";
import { IPolicyEntity, IStatementEntity } from "./types";

// Actions to work with policies
export const loadPolicies = createAsyncThunk(
  "policies/loadPolicies",
  async ({
    tenantId,
    msalInstance,
  }: {
    tenantId: string;
    msalInstance: IMsalContext;
  }) => {
    const token = await getAccessToken(msalInstance);

    const {
      data,
    }: {
      data: {
        LastModified: string;
        EntityName: string;
        Config: ITenantConfig;
        Plugins?: IPlugins;
      };
    } = await axios.get(`${url}tenants/${tenantId}`, {
      headers: getHeaders(token),
    });

    return data.Plugins;
  },
);

export const deletePolicy = createAsyncThunk(
  "policies/deletePolicy",
  async ({
    Id,
    tenant,
    msalInstance,
  }: {
    Id: string;
    tenant: ITenantProps;
    msalInstance: IMsalContext;
  }) => {
    const token = await getAccessToken(msalInstance);
    const currentPlugins: IPolicyPlugin = tenant.Plugins;

    if (currentPlugins && currentPlugins.AuthPolicyEngine) {
      const newData = {
        ...tenant,
        plugins: {
          ...tenant.Plugins,
          AuthPolicyEngine: {
            ...currentPlugins.AuthPolicyEngine,
            policies: currentPlugins.AuthPolicyEngine.policies?.filter(
              item => item.Id !== Id,
            ),
          },
        },
      };

      await axios.put(`${url}tenants/${tenant.EntityName}`, newData, {
        headers: getHeaders(token),
      });
    }

    return Id;
  },
);

export const createPolicy = createAsyncThunk(
  "policies/createPolicy",
  async (
    {
      PolicyId,
      Statement,
      tenant,
      msalInstance,
    }: {
      PolicyId: string;
      Statement: IStatementEntity[];
      tenant: ITenantProps;
      msalInstance: IMsalContext;
    },
    { rejectWithValue },
  ) => {
    try {
      const token = await getAccessToken(msalInstance);
      const currentPlugins: IPolicyPlugin = tenant.Plugins;

      if (currentPlugins.AuthPolicyEngine?.policies) {
        const newData = {
          ...tenant,
          Plugins: {
            ...currentPlugins,
            AuthPolicyEngine: {
              ...currentPlugins.AuthPolicyEngine,
              policies: [
                ...currentPlugins.AuthPolicyEngine.policies,
                {
                  Id: PolicyId,
                  Statement: Statement,
                },
              ],
            },
          },
        };

        const {
          data,
        }: {
          data: {
            Plugins: {
              AuthPolicyEngine: {
                policies: IPolicyEntity[];
              };
            };
          };
        } = await axios.put(`${url}tenants/${tenant.EntityName}`, newData, {
          headers: getHeaders(token),
        });

        const result = data.Plugins.AuthPolicyEngine.policies.find(
          item => item.Id === PolicyId,
        );

        return result;
      }
    } catch (err) {
      if (!navigator.onLine) {
        return rejectWithValue({
          title: "Problem connecting",
          text: "Check your internet connection and try again.",
          connection: true,
        });
      } else if (axios.isAxiosError(err) && err.response && err.response.data) {
        return rejectWithValue({
          title: "Saving could not be completed",
          text:
            (err.response.data as { detail: { Error: string } }).detail.Error ||
            "An unknown error occured. Please wait a few minutes and then try again or contact Administrator.",
        });
      }
    }
  },
);

export const updatePolicy = createAsyncThunk(
  "policies/updatePolicy",
  async (
    {
      PolicyId,
      Statement,
      tenant,
      msalInstance,
    }: {
      PolicyId: string;
      Statement: IStatementEntity[];
      tenant: ITenantProps;
      msalInstance: IMsalContext;
    },
    { rejectWithValue },
  ) => {
    try {
      const token = await getAccessToken(msalInstance);
      const currentPlugins: IPolicyPlugin = tenant.Plugins;

      if (currentPlugins.AuthPolicyEngine?.policies) {
        const newData = {
          ...tenant,
          Plugins: {
            ...currentPlugins,
            AuthPolicyEngine: {
              ...currentPlugins.AuthPolicyEngine,
              policies: [
                ...currentPlugins.AuthPolicyEngine.policies.filter(
                  item => item.Id !== PolicyId,
                ),
                {
                  Id: PolicyId,
                  Statement: Statement,
                },
              ],
            },
          },
        };

        const {
          data,
        }: {
          data: {
            Plugins: {
              AuthPolicyEngine: {
                policies: IPolicyEntity[];
              };
            };
          };
        } = await axios.put(`${url}tenants/${tenant.EntityName}`, newData, {
          headers: getHeaders(token),
        });

        const result = data.Plugins.AuthPolicyEngine.policies.find(
          item => item.Id === PolicyId,
        );

        return result;
      }
    } catch (err) {
      if (!navigator.onLine) {
        return rejectWithValue({
          title: "Problem connecting",
          text: "Check your internet connection and try again.",
        });
      } else if (axios.isAxiosError(err) && err.response && err.response.data) {
        return rejectWithValue({
          title: "Saving could not be completed",
          text:
            (err.response.data as { detail: { Error: string } }).detail.Error ||
            "An unknown error occured. Please wait a few minutes and then try again or contact Administrator.",
        });
      }
    }
  },
);
