import { ApplicationState } from "../types";
import { Hints } from "./types";

export const getAllHints = (state: ApplicationState): Hints =>
  state.hintsSlice.data;

export const getActionHints = (
  state: ApplicationState,
): Array<{
  description: string;
  name: string;
}> => state.hintsSlice.data.action.values;

export const getResourceHints = (
  state: ApplicationState,
): Array<{
  description: string;
  name: string;
}> => state.hintsSlice.data.resource.values;

export const getSubjectAttributeHints = (
  state: ApplicationState,
): {
  [index: string]: {
    keys: {
      [index: string]: {
        descr: string;
        values: Array<{
          description: string;
          name: string;
        }>;
      };
    };
  };
} => state.hintsSlice.data.condition.elements;

export const getSubjects = (state: ApplicationState): Array<string> =>
  Object.keys(state.hintsSlice.data.condition.elements);
