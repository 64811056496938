import React, { memo } from "react";
import { Card, Descriptions, Input } from "antd";

import TextArea from "antd/lib/input/TextArea";
import { ITenant } from "../../pages/Tenants/types";

import "./style.scss";

const GeneralForm = ({
  data,
  setData,
}: {
  data: ITenant;
  setData: (value: ITenant) => void;
}) => {
  const handleChangeTenantId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      tenantId: event.target.value,
    });
  };

  const handleChangeDescr = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData({
      ...data,
      description: event.target.value,
    });
  };

  return (
    <Card
      title="General"
      className="tenant-general-form"
      bodyStyle={{
        paddingLeft: 130,
      }}
    >
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="Tenant ID"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Input onChange={handleChangeTenantId} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          className="descr"
          label="Description"
          labelStyle={{
            width: 93,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TextArea
            onChange={handleChangeDescr}
            placeholder="Add a description"
          ></TextArea>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default memo(GeneralForm);
