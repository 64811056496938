import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Card, Drawer, Empty, Input, message, Select, Switch } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import { IProps } from "./types.props";
import { IPluginsList } from "../../pages/Tenant/types";
import { updateTenant } from "../../http/userApi";
import { ICompletedTenant } from "../../pages/AddTenant/types";
import { useMsal } from "@azure/msal-react";

import Details from "./Details";
import Button from "../../common/Button";

const PluginsComponent = ({
  tenant,
  title,
  data,
  pluginsList,
  setPluginsList,
}: IProps) => {
  const msalInstance = useMsal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchPlugins, setSearchPlugins] = useState("");
  const [selectedPlugin, setSelectedPlugin] = useState<IPluginsList>();

  const [pluginsData, setPluginsData] = useState({
    SecretsIamSession: {
      MAX_AD_GROUPS: 0,
      SKIP_SESSION_TAGS: false,
      pattern: "",
    },
    SecretsIamUser: {
      IAM_GROUP_TARGET_PREFIX: "",
      IAM_USER_SESSION_LIFETIME: "",
      MAX_AD_GROUPS: 0,
      SESSION_MODE: false,
      pattern: "",
    },
  });

  useEffect(() => {
    setPluginsData({
      ...pluginsData,
      SecretsIamSession: {
        ...pluginsData.SecretsIamSession,
        MAX_AD_GROUPS: tenant.Plugins.SecretsIamSession.MAX_AD_GROUPS,
        SKIP_SESSION_TAGS: tenant.Plugins.SecretsIamSession.SKIP_SESSION_TAGS,
        pattern: tenant.Plugins.SecretsIamSession.pattern,
      },
      SecretsIamUser: {
        ...pluginsData.SecretsIamUser,
        IAM_GROUP_TARGET_PREFIX:
          tenant.Plugins.SecretsIamUser.IAM_GROUP_TARGET_PREFIX,
        IAM_USER_SESSION_LIFETIME:
          tenant.Plugins.SecretsIamUser.IAM_USER_SESSION_LIFETIME,
        MAX_AD_GROUPS: tenant.Plugins.SecretsIamUser.MAX_AD_GROUPS,
        SESSION_MODE: tenant.Plugins.SecretsIamUser.SESSION_MODE,
        pattern: tenant.Plugins.SecretsIamUser.pattern,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant.Plugins]);

  const [searchedPlugins, setSearchedPlugins] = useState<IPluginsList[]>([]);

  useEffect(() => {
    if (pluginsList.length) {
      setSelectedPlugin({
        name: pluginsList[0].name,
        enabled: pluginsList[0].enabled,
        pluginType: pluginsList[0].pluginType,
        label: pluginsList[0].label,
        descr: pluginsList[0].descr,
      });
    }
  }, [pluginsList]);

  useEffect(
    () =>
      setSearchedPlugins(
        pluginsList.filter(item =>
          item.name.toLowerCase().includes(searchPlugins.toLowerCase()),
        ),
      ),
    [searchPlugins, pluginsList],
  );

  const showDrawer = () => {
    setOpen(true);
  };

  const handleSelected = useCallback(
    (
      name: string,
      isEnabled: boolean,
      type: string,
      label?: string,
      descr?: string,
    ) => {
      setSelectedPlugin({
        name: name,
        enabled: isEnabled,
        pluginType: type,
        label: label,
        descr: descr,
      });
      showDrawer();
    },
    [],
  );

  const onClose = () => {
    setOpen(false);
  };

  const handleSearchPlugins = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchPlugins(event.target.value);

  const handleFilterByEnabled = (value: string) => {
    if (value === "only") {
      setSearchedPlugins(pluginsList.filter(item => item.enabled));
    } else {
      setSearchedPlugins(pluginsList);
    }
  };

  const handleSave = useCallback(async () => {
    setLoading(true);

    const newData: ICompletedTenant = {
      ...data,
      EntityName: data.EntityName,
      Description: "",
      Plugins: {
        ...data.Plugins,
        SecretsIamSession: {
          ...data.Plugins.SecretsIamSession,
          MAX_AD_GROUPS: pluginsData.SecretsIamSession.MAX_AD_GROUPS,
          SKIP_SESSION_TAGS: pluginsData.SecretsIamSession.SKIP_SESSION_TAGS,
          pattern: pluginsData.SecretsIamSession.pattern,
        },
        SecretsIamUser: {
          ...data.Plugins.SecretsIamUser,
          IAM_GROUP_TARGET_PREFIX:
            pluginsData.SecretsIamUser.IAM_GROUP_TARGET_PREFIX,
          IAM_USER_SESSION_LIFETIME:
            pluginsData.SecretsIamUser.IAM_USER_SESSION_LIFETIME,
          MAX_AD_GROUPS: pluginsData.SecretsIamUser.MAX_AD_GROUPS,
          SESSION_MODE: pluginsData.SecretsIamUser.SESSION_MODE,
          pattern: pluginsData.SecretsIamUser.pattern,
        },
      },
      InternalPolicies: [...tenant.InternalPolicies],
      Addons: {},
      Config: data.Config,
    };

    const result: {
      data: string | null;
      error: null | string;
    } = await updateTenant(newData, msalInstance);

    if (result?.data) {
      message.success({
        content: result.data,
        duration: 3,
      });
    }

    if (result.error) {
      message.error({
        content: result.error,
        duration: 3,
      });
    }

    setLoading(false);
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePluginEnabled = useCallback(
    async (name: string | undefined, checked: boolean) => {
      try {
        setLoading(true);

        if (name) {
          const newData: ICompletedTenant = {
            EntityName: data.EntityName,
            Description: "",
            Plugins: {
              ...data.Plugins,
              [name]: {
                enabled: checked,
              },
            },
            InternalPolicies: [...tenant.InternalPolicies],
            Addons: {},
            Config: data.Config,
          };

          const result: {
            data: string | null;
            error: null | string;
          } = await updateTenant(newData, msalInstance);

          if (result?.data) {
            if (selectedPlugin && selectedPlugin.name === name) {
              setSelectedPlugin({
                ...selectedPlugin,
                enabled: !selectedPlugin.enabled,
              });

              setPluginsList(
                pluginsList.map(plugin =>
                  plugin.name === name
                    ? { ...plugin, enabled: checked }
                    : plugin,
                ),
              );
            } else {
              setPluginsList(
                pluginsList.map(plugin =>
                  plugin.name === name
                    ? { ...plugin, enabled: checked }
                    : plugin,
                ),
              );
            }

            message.success({
              content: result.data,
              duration: 3,
            });
          }

          if (result.error) {
            message.error({
              content: result.error,
              duration: 3,
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.Config, data.EntityName, data.Plugins, pluginsList],
  );

  return (
    <>
      <Card
        title={title}
        className="plugins-card"
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="plugins-card-fields">
          <Input.Search
            placeholder="Search setting..."
            className="plugins-card-search"
            onChange={handleSearchPlugins}
          />
          <Select defaultValue="all" onChange={handleFilterByEnabled}>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="only">Only enabled</Select.Option>
          </Select>
        </div>
        <div className="plugins-card-body">
          {searchedPlugins.length ? (
            searchedPlugins.map((item, i) => (
              <Card
                title={item.label ? item.label : item.name}
                extra={
                  <Switch
                    onChange={checked =>
                      handleChangePluginEnabled(item.name, checked)
                    }
                    loading={loading}
                    checked={item.enabled}
                  />
                }
                key={i}
                className={`plugin-item ${
                  item.enabled ? "plugin-item-checked" : ""
                }`}
              >
                <p>{item.descr ? item.descr : ""}</p>
                <Button
                  onClick={() =>
                    handleSelected(
                      item.name,
                      item.enabled,
                      item.pluginType,
                      item.label,
                      item.descr,
                    )
                  }
                  icon={<SettingOutlined />}
                >
                  Open details
                </Button>
              </Card>
            ))
          ) : (
            <Empty className="empty-plugins" />
          )}
        </div>
      </Card>
      {selectedPlugin ? (
        <Drawer
          title={selectedPlugin.label}
          placement="right"
          width={500}
          onClose={onClose}
          open={open}
          className="plugin-drawer"
        >
          <div className="plugin-drawer-descr">{selectedPlugin.descr}</div>
          <div className="plugin-drawer-body">
            <Card
              title="General"
              extra={
                <div className="plugin-drawer-extra">
                  <span className="plugin-drawer-switch">
                    {selectedPlugin.enabled
                      ? "Plugin is enabled"
                      : "Plugin is disabled"}
                  </span>
                  <Switch
                    checked={selectedPlugin.enabled}
                    onChange={checked =>
                      handleChangePluginEnabled(selectedPlugin.name, checked)
                    }
                    loading={loading}
                  />
                </div>
              }
              className="plugin-card"
            >
              <Details
                selected={selectedPlugin}
                pluginsData={pluginsData}
                setPluginsData={setPluginsData}
              />
            </Card>
          </div>
          <div className="plugin-drawer-footer">
            <Button onClick={onClose}>Cansel</Button>
            <Button
              onClick={handleSave}
              className="plugin-btn-save"
              loading={loading}
              type="primary"
            >
              Save
            </Button>
          </div>
        </Drawer>
      ) : null}
    </>
  );
};

export default PluginsComponent;
